import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogDisclosure,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Body,
  Box,
  Button,
  ButtonGroup,
  CheckIcon,
  Circle,
  DangerButton,
  DangerIcon,
  Flex,
  Input,
  SmallBody,
  Spinner,
  Stack,
  Subtitle,
  Switch,
  Textarea,
  useSnackbar
} from '@woorcs/design-system'
import * as t from 'io-ts'
import {
  Form,
  Formik,
  FormikHelpers,
  useFormikContext,
  FormikContext
} from 'formik'
import { getFormikValidator } from '@woorcs/utils/formik'
import { memo, ReactElement, useCallback, useMemo } from 'react'
import { useMutation } from 'urql'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { DialogDismiss } from '@ariakit/react'

import { AutoSave } from '@app/components/AutoSave'
import { FormField } from '@app/components'

import {
  DeleteFormDocument,
  FormDetailsPageFormFragment,
  UpdateFormCustomerRequiredDocument,
  UpdateFormDescriptionDocument,
  UpdateFormTitleDocument
} from './__generated__/FormDetails'
import { SubmissionFilenameForm } from './SubmissionFilenameForm'

interface SavingInputAddonProps {
  isSaving: boolean
  isError: boolean
}

const SavingInputAddon = ({ isSaving, isError }: SavingInputAddonProps) => {
  const formik = useFormikContext()

  if (isSaving) {
    return <Spinner size='small' />
  }

  if (isError) {
    return (
      <Circle
        radius={18}
        display='flex'
        bg='danger'
        justifyContent='center'
        alignItems='center'
      >
        <DangerIcon color='white' size='small' />
      </Circle>
    )
  }

  if (!formik.dirty && formik.isValid) {
    return (
      <Circle
        radius={18}
        display='flex'
        bg='success'
        justifyContent='center'
        alignItems='center'
      >
        <CheckIcon color='white' size='small' />
      </Circle>
    )
  }

  return null
}

const formTitleForm = t.type({
  title: t.string
})

type FormTitleFormValue = t.TypeOf<typeof formTitleForm>

const validateFormTitleForm = getFormikValidator(formTitleForm)

interface FormTitleFieldProps {
  formId: string
  initialTitle: string
}

const FormTitleField = ({ formId, initialTitle }: FormTitleFieldProps) => {
  const { showSnackbar } = useSnackbar()
  const { t } = useTranslation('form-details')
  const [mutationState, updateFormTitle] = useMutation(UpdateFormTitleDocument)
  const initialValues = useMemo((): FormTitleFormValue => {
    return {
      title: initialTitle
    }
  }, [initialTitle])

  const handleSubmit = useCallback(
    (
      values: FormTitleFormValue,
      helpers: FormikHelpers<FormTitleFormValue>
    ) => {
      updateFormTitle({
        input: {
          formId,
          title: values.title
        }
      })
        .then(() => {
          helpers.resetForm({ values })

          showSnackbar({
            variant: 'success',
            title: t('settings.general.titleField.updateSuccess')
          })
        })
        .catch(() => {
          showSnackbar({
            title: t('settings.general.titleField.updateError'),
            variant: 'danger'
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
        })
    },
    [formId, showSnackbar, t, updateFormTitle]
  )

  return (
    <Formik
      initialValues={initialValues}
      validate={validateFormTitleForm}
      onSubmit={handleSubmit}
    >
      <Form>
        <AutoSave debounceMs={300} />
        <FormField
          name='title'
          label={t('settings.general.titleField.label')}
          mb={2}
        >
          <Input
            rightAddon={
              <SavingInputAddon
                isSaving={mutationState.fetching}
                isError={Boolean(mutationState.error)}
              />
            }
          />
        </FormField>
      </Form>
    </Formik>
  )
}

const formDescriptionForm = t.type({
  description: t.string
})

type FormDescriptionFormValue = t.TypeOf<typeof formDescriptionForm>

const validateFormDescriptionForm = getFormikValidator(formDescriptionForm)

interface FormDescriptionFieldProps {
  formId: string
  initialDescription: string | null
}

const FormDescriptionField = ({
  formId,
  initialDescription
}: FormDescriptionFieldProps) => {
  const { t } = useTranslation('form-details')
  const { showSnackbar } = useSnackbar()
  const [mutationState, updateFormDescription] = useMutation(
    UpdateFormDescriptionDocument
  )
  const initialValues = useMemo((): FormDescriptionFormValue => {
    return {
      description: initialDescription ?? ''
    }
  }, [initialDescription])

  const handleSubmit = useCallback(
    (
      values: FormDescriptionFormValue,
      helpers: FormikHelpers<FormDescriptionFormValue>
    ) => {
      updateFormDescription({
        input: {
          formId,
          description: values.description
        }
      })
        .then(() => {
          helpers.resetForm({ values })

          showSnackbar({
            variant: 'success',
            title: t('settings.general.descriptionField.updateSuccess')
          })
        })
        .catch(() => {
          showSnackbar({
            title: t('settings.general.descriptionField.updateError'),
            variant: 'danger'
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
        })
    },
    [formId, showSnackbar, t, updateFormDescription]
  )

  return (
    <Formik
      initialValues={initialValues}
      validate={validateFormDescriptionForm}
      onSubmit={handleSubmit}
    >
      <Form>
        <AutoSave debounceMs={300} />
        <FormField
          name='description'
          label={t('settings.general.descriptionField.label')}
          mb={2}
        >
          <Textarea
            maxLength={250}
            rightAddon={
              <SavingInputAddon
                isSaving={mutationState.fetching}
                isError={Boolean(mutationState.error)}
              />
            }
          />
        </FormField>
        <Box mt={1} textAlign='right' color='grey.500' fontSize='mini'>
          <FormikContext.Consumer>
            {({ values }) => (
              <span>
                {t('settings.general.descriptionField.charactersRemaining', {
                  count: 250 - values.description.length
                })}
              </span>
            )}
          </FormikContext.Consumer>
        </Box>
      </Form>
    </Formik>
  )
}

const formCustomerRequiredForm = t.type({
  requireCustomer: t.boolean
})

type FormCustomerRequiredFormValue = t.TypeOf<typeof formCustomerRequiredForm>

const validateFormCustomerRequiredForm = getFormikValidator(
  formCustomerRequiredForm
)

interface FormCustomerRequiredFieldProps {
  formId: string
  initialRequireCustomer: boolean
}

const FormCustomerRequiredField = ({
  formId,
  initialRequireCustomer
}: FormCustomerRequiredFieldProps) => {
  const { t } = useTranslation('form-details')
  const { showSnackbar } = useSnackbar()
  const [, updateFormCustomerRequired] = useMutation(
    UpdateFormCustomerRequiredDocument
  )
  const initialValues = useMemo((): FormCustomerRequiredFormValue => {
    return {
      requireCustomer: initialRequireCustomer
    }
  }, [initialRequireCustomer])

  const handleSubmit = useCallback(
    (
      values: FormCustomerRequiredFormValue,
      helpers: FormikHelpers<FormCustomerRequiredFormValue>
    ) => {
      updateFormCustomerRequired({
        input: {
          formId,
          requireCustomer: values.requireCustomer
        }
      })
        .then(() => {
          helpers.resetForm({ values })

          showSnackbar({
            variant: 'success',
            title: t('settings.submissions.customerRequiredField.updateSuccess')
          })
        })
        .catch(() => {
          showSnackbar({
            title: t('settings.submissions.customerRequiredField.updateError'),
            variant: 'danger'
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
        })
    },
    [formId, showSnackbar, t, updateFormCustomerRequired]
  )

  return (
    <Formik
      initialValues={initialValues}
      validate={validateFormCustomerRequiredForm}
      onSubmit={handleSubmit}
    >
      <Form>
        <AutoSave debounceMs={300} />
        <FormField
          name='requireCustomer'
          label={t('settings.submissions.customerRequiredField.label')}
          description={t(
            'settings.submissions.customerRequiredField.description'
          )}
          mb={2}
        >
          <Switch />
        </FormField>
      </Form>
    </Formik>
  )
}

interface ConfirmDeleteFormAlertProps {
  onConfirm(): void
  children: ReactElement
}

const ConfirmDeleteFormAlert = memo(
  ({ onConfirm, children }: ConfirmDeleteFormAlertProps) => {
    const { t } = useTranslation('form-details')

    return (
      <AlertDialog>
        <AlertDialogDisclosure>{children}</AlertDialogDisclosure>

        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t('settings.deleteForm.confirm.title')}
            </AlertDialogTitle>
          </AlertDialogHeader>

          <AlertDialogBody>
            <Body>{t('settings.deleteForm.confirm.description')}</Body>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <DialogDismiss render={<Button colorVariant='secondary' />}>
                {t('settings.deleteForm.confirm.cancelButton')}
              </DialogDismiss>
              <Button colorVariant='danger' onClick={onConfirm}>
                {t('settings.deleteForm.confirm.deleteButton')}
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }
)

interface DeleteFormSectionProps {
  form: FormDetailsPageFormFragment
}

const DeleteFormSection = ({ form }: DeleteFormSectionProps) => {
  const [, deleteForm] = useMutation(DeleteFormDocument)
  const navigate = useNavigate()
  const { t } = useTranslation('form-details')

  return (
    <Box
      borderColor='grey.50'
      borderStyle='solid'
      borderWidth={1}
      borderRadius='large'
      p={4}
    >
      <Flex justifyContent='space-between' alignItems='center'>
        <Box>
          <Subtitle fontWeight='bold' mb={1}>
            {t('settings.deleteForm.title')}
          </Subtitle>
          <SmallBody>{t('settings.deleteForm.description')}</SmallBody>
        </Box>
        <ConfirmDeleteFormAlert
          onConfirm={() => {
            deleteForm({
              input: {
                formId: form.id
              }
            }).finally(() => {
              navigate('/forms')
            })
          }}
        >
          <DangerButton>{t('settings.deleteForm.deleteButton')}</DangerButton>
        </ConfirmDeleteFormAlert>
      </Flex>
    </Box>
  )
}

interface FormDetailsSettingsTabProps {
  form: FormDetailsPageFormFragment
}

export const FormDetailsSettingsTab = ({
  form
}: FormDetailsSettingsTabProps) => {
  const { t } = useTranslation('form-details')

  return (
    <Flex py={8} gap={4} flexDirection='column'>
      <Box
        borderColor='grey.50'
        borderStyle='solid'
        borderWidth={1}
        borderRadius='large'
        p={4}
      >
        <Subtitle mb={4}>{t('settings.general.title')}</Subtitle>

        <Stack spacing={4}>
          <Box>
            <FormTitleField initialTitle={form.title} formId={form.id} />
          </Box>
          <Box>
            <FormDescriptionField
              initialDescription={form.description}
              formId={form.id}
            />
          </Box>
        </Stack>
      </Box>
      <Box
        borderColor='grey.50'
        borderStyle='solid'
        borderWidth={1}
        borderRadius='large'
        p={4}
      >
        <Box>
          <Subtitle mb={4}>{t('settings.submissions.title')}</Subtitle>
          <FormCustomerRequiredField
            initialRequireCustomer={form.requireCustomer}
            formId={form.id}
          />
        </Box>
      </Box>
      <Box
        borderColor='grey.50'
        borderStyle='solid'
        borderWidth={1}
        borderRadius='large'
        p={4}
      >
        <Box>
          <Subtitle mb={4}>{t('settings.exports.title')}</Subtitle>
          <SubmissionFilenameForm form={form} />
        </Box>
      </Box>

      <DeleteFormSection form={form} />
    </Flex>
  )
}
