import { forwardRef, ReactNode } from 'react'
import { space, rem } from '@woorcs/utils'
import { AnimatePresence } from 'framer-motion'
import * as Ariakit from '@ariakit/react'

import { CloseButton } from '../../buttons'
import { DialogBackdrop } from '../DialogBackdrop'

import { ModalContainer, ModalContainerProps } from './ModalContainer'

type ModalChildren =
  | ReactNode
  | ((
      state: Pick<Ariakit.DialogStore, 'show' | 'hide' | 'toggle'>
    ) => ReactNode)

export interface ModalDialogProps
  extends Omit<ModalContainerProps, 'children'> {
  showCloseButton?: boolean
  centerContent?: boolean
  unmountOnHide?: boolean
  portal?: boolean
  children: ModalChildren
}

const defaultWidth = rem(620)

export const ModalDialog = forwardRef<HTMLDivElement, ModalDialogProps>(
  (
    {
      width = defaultWidth,
      showCloseButton = true,
      centerContent = true,
      unmountOnHide = false,
      portal = true,
      children,
      ...other
    },
    ref
  ) => {
    const store = Ariakit.useDialogContext()

    if (!store) {
      throw new Error(
        'No modal context found. Make sure you are using a ModalProvider.'
      )
    }

    const mounted = store.useState('mounted')

    return (
      <AnimatePresence>
        {mounted && (
          <Ariakit.Dialog
            ref={ref}
            backdrop={false}
            render={(props) => (
              <DialogBackdrop centerContent={centerContent}>
                <ModalContainer width={width} {...other} {...props} />
              </DialogBackdrop>
            )}
            // backdrop={
            //   withBackdrop && <DialogBackdrop centerContent={centerContent} />
            // }
            // hideOnInteractOutside={false}
            portal={portal}
            unmountOnHide={unmountOnHide}
            modal
            alwaysVisible
          >
            {typeof children === 'function' ? children(store) : children}

            {showCloseButton && (
              <Ariakit.DialogDismiss
                render={
                  <CloseButton
                    position='absolute'
                    top={space(2)}
                    right={space(2)}
                  />
                }
              />
            )}
          </Ariakit.Dialog>
        )}
      </AnimatePresence>
    )
  }
)
