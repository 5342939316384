import {
  FormFieldLabel,
  Box,
  Flex,
  DocumentIcon,
  Text
} from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { SubmissionFilenameFormat } from '@woorcs/submission-filename-format'
import { FormDocument } from '@woorcs/form'

import { formatFilename } from './formatFilename'

interface SubmissionFilenamePreviewProps {
  filenameFormat: SubmissionFilenameFormat
  formDefinition: FormDocument.FormDocument
}

export const SubmissionFilenamePreview = ({
  filenameFormat,
  formDefinition
}: SubmissionFilenamePreviewProps) => {
  const { t } = useTranslation('form-details')

  const filename = pipe(
    formatFilename(filenameFormat, formDefinition),
    O.map((filename) => `${filename}.pdf`),
    O.getOrElse(() => '[UUID].pdf')
  )

  return (
    <Box width='100%'>
      <FormFieldLabel>
        {t('settings.exports.filenameFormatField.preview')}:
      </FormFieldLabel>
      <Flex
        width='100%'
        p={3}
        border='1px solid'
        borderColor='grey.200'
        alignItems='center'
        gap={3}
      >
        <DocumentIcon />
        <Text fontSize='small'>{filename}</Text>
      </Flex>
    </Box>
  )
}
