import { ChangeEvent, useCallback } from 'react'
import { palette } from '@woorcs/design-tokens'
import * as Ariakit from '@ariakit/react'

import { Input, InputProps } from '../Input'
import { PopoverContainer } from '../../overlays'
import { Swatch } from '../../data'

const defaultColorPalette = [
  palette.red['500'],
  palette.red['300'],
  palette.yellow['300'],
  palette.yellow['500'],
  palette.secondary['300'],
  palette.secondary['500'],
  palette.primary['300'],
  palette.primary['500']
]

interface ColorOptionProps {
  color: string
  onClick?(color: string): void
}

const ColorOption = ({ color, onClick }: ColorOptionProps) => {
  const handleClick = useCallback(() => {
    onClick?.(color)
  }, [color, onClick])

  return (
    <Ariakit.ComboboxItem
      key={color}
      render={(props) => (
        <Swatch
          color={color}
          {...props}
          css={{
            cursor: 'pointer'
          }}
          display='block'
          mr={1}
          onClick={handleClick}
        />
      )}
    />
  )
}

type ColorInputProps = Omit<InputProps, 'value' | 'onChange'> & {
  defaultColors?: string[]
  value?: string
  onChange?(value: string): void
}

export const ColorInput = ({
  value,
  defaultColors = defaultColorPalette,
  onChange,
  ...other
}: ColorInputProps) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value)
    },
    [onChange]
  )

  const handleColorOptionClick = useCallback(
    (color: string) => {
      onChange?.(color)
    },
    [onChange]
  )

  return (
    <Ariakit.DialogProvider>
      <Ariakit.ComboboxProvider>
        <Ariakit.Combobox
          value={value}
          render={(props) => (
            <Input
              autoComplete='off'
              leftAddon={
                <Swatch
                  style={{ pointerEvents: 'none' }}
                  color={value}
                  ml={-1}
                />
              }
              {...other}
              {...props}
            />
          )}
          onChange={handleChange}
          onBlur={other.onBlur}
          onFocus={other.onFocus}
        />
        <Ariakit.ComboboxPopover
          gutter={8}
          tabIndex={-1}
          portal={false}
          render={(props) => (
            <PopoverContainer
              display='flex'
              flexDirection='row'
              p={2}
              {...props}
            />
          )}
        >
          {defaultColors.map((color) => (
            <ColorOption
              key={color}
              color={color}
              onClick={handleColorOptionClick}
            />
          ))}
        </Ariakit.ComboboxPopover>
      </Ariakit.ComboboxProvider>
    </Ariakit.DialogProvider>
  )
}
