import { useCallback, useState } from 'react'
import * as R from 'fp-ts/Record'
import * as O from 'fp-ts/Option'
import { pipe, constVoid } from 'fp-ts/function'
import { createContext } from '@woorcs/utils'

import { Filters, FilterValues, ValueOfFiltersConfig } from './types'

export interface FiltersContext<T extends Filters = Filters> {
  availableFilters: T
  filters: FilterValues<T>
  setFilter(key: keyof T, value: O.Option<unknown>, replace?: boolean): void
  clearFilters(): void
  offset: number
  limit: number
  setOffset(offset: number): void
  setLimit(limit: number): void
}

export const useFilters = <T extends Filters>(
  availableFilters: T,
  initialValues: Partial<ValueOfFiltersConfig<T>> = {}
): FiltersContext<T> => {
  const initialState = pipe(
    availableFilters,
    R.mapWithIndex((key) => pipe(initialValues, R.lookup(key) as any))
  ) as FilterValues<T>
  const [filters, setFilters] = useState<FilterValues<T>>(initialState)
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(10)

  const setFilter = useCallback(
    (key: string, value: O.Option<unknown>, replace = false) => {
      pipe(
        replace ? initialState : filters,
        R.updateAt(key, value) as any,
        O.foldW(constVoid, setFilters)
      )
      setOffset(0)
    },
    [initialState, filters]
  )

  const clearFilters = useCallback(() => {
    setFilters(initialState)
    setOffset(0)
  }, [initialState])

  return {
    availableFilters,
    filters,
    setFilter,
    clearFilters,
    offset,
    limit,
    setLimit,
    setOffset
  }
}

export const [FiltersProvider, useContext] = createContext<FiltersContext>()

export const useFiltersContext = <T extends Filters = Filters>() =>
  useContext() as FiltersContext<T>
