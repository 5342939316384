/* eslint-disable complexity */
import { FormDocument } from '@woorcs/form'
import {
  SubmissionFilenameComponent,
  SubmissionFilenameFormat,
  formatSubmissionFilename
} from '@woorcs/submission-filename-format'
import * as O from 'fp-ts/Option'

const getComponentValue = (component: SubmissionFilenameComponent) => {
  switch (component.type) {
    case 'customer':
      switch (component.valueType.name) {
        case 'NAME':
          return O.some('Customer AB')
        case 'ID':
          return O.some('1234567890')
        default:
          return O.none
      }
    case 'submitter':
      switch (component.valueType.name) {
        case 'NAME':
          return O.some('John Doe')
        case 'FIRST_NAME':
          return O.some('John')
        case 'LAST_NAME':
          return O.some('Doe')
        case 'ID':
          return O.some('1234567890')
        case 'EMAIL':
          return O.some('john.doe@example.com')
        default:
          return O.none
      }
    case 'form':
      switch (component.valueType.name) {
        case 'TITLE':
          return O.some('Form Title')
        case 'ID':
          return O.some('1234567890')
        default:
          return O.none
      }
    case 'formField':
      switch (component.valueType.name) {
        case 'TextInput':
          return O.some('John Doe')
        case 'NumberInput':
          return O.some('1234567890')
        case 'DateInput':
          return O.some(new Date())
        case 'TimeInput':
          return O.some('12:00')
        case 'EmailInput':
          return O.some('john.doe@example.com')
        default:
          return O.none
      }
    case 'submission':
      switch (component.valueType.name) {
        case 'ID':
          return O.some('1234567890')
        case 'CREATED_AT':
          return O.some(new Date())
        case 'PUBLISHED_AT':
          return O.some(new Date())
        case 'LANGUAGE':
          return O.some('en')
        case 'VERSION':
          return O.some('1')
        default:
          return O.none
      }
    default:
      return O.none
  }
}

export const formatFilename = (
  format: SubmissionFilenameFormat,
  formDefinition: FormDocument.FormDocument
) => {
  return formatSubmissionFilename({
    filenameFormat: format,
    formDefinition,
    getComponentValue
  })
}
