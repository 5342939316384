/* eslint-disable complexity */
import {
  Badge,
  BriefcaseIcon,
  CloseIcon,
  DocumentIcon,
  Flex,
  FormIcon,
  IconButton,
  Text,
  UserIcon
} from '@woorcs/design-system'
import { SubmissionFilenameComponent } from '@woorcs/submission-filename-format'
import { formElementIcon } from '@woorcs/inspection-form-editor/src/element'
import { absurd } from 'fp-ts/function'
import { useTranslation } from 'react-i18next'
import { forwardRef } from 'react'

import {
  CustomerComponentMenu,
  FormComponentMenu,
  FormFieldComponentMenu,
  SelectFilenameComponentMenu,
  SubmissionComponentMenu,
  SubmitterComponentMenu
} from './SelectFilenameComponentMenu'
import { Field } from './SelectFieldModal'

interface FilenameComponentBadgeProps {
  index: number
  component: SubmissionFilenameComponent
  onRemove: (index: number) => void
}

const FilenameComponentBadge = forwardRef<
  HTMLDivElement,
  FilenameComponentBadgeProps
>(({ index, component, onRemove, ...other }, ref) => {
  const { t } = useTranslation('form-details')

  const getComponentLabel = (component: SubmissionFilenameComponent) => {
    switch (component.type) {
      case 'formField':
        return `${t(
          'settings.exports.filenameFormatField.components.formField.label'
        )} (${component.valueType.name})`
      case 'submitter': {
        switch (component.valueType.name) {
          case 'NAME':
            return t(
              'settings.exports.filenameFormatField.components.submitter.properties.name'
            )
          case 'ID':
            return t(
              'settings.exports.filenameFormatField.components.submitter.properties.id'
            )
          case 'EMAIL':
            return t(
              'settings.exports.filenameFormatField.components.submitter.properties.email'
            )
          case 'FIRST_NAME':
            return t(
              'settings.exports.filenameFormatField.components.submitter.properties.firstName'
            )
          case 'LAST_NAME':
            return t(
              'settings.exports.filenameFormatField.components.submitter.properties.lastName'
            )
        }
      }
      case 'customer': {
        switch (component.valueType.name) {
          case 'NAME':
            return t(
              'settings.exports.filenameFormatField.components.customer.properties.name'
            )
          case 'ID':
            return t(
              'settings.exports.filenameFormatField.components.customer.properties.id'
            )
        }
      }
      case 'form': {
        switch (component.valueType.name) {
          case 'TITLE':
            return t(
              'settings.exports.filenameFormatField.components.form.properties.title'
            )
          case 'ID':
            return t(
              'settings.exports.filenameFormatField.components.form.properties.id'
            )
        }
      }
      case 'submission': {
        switch (component.valueType.name) {
          case 'ID':
            return t(
              'settings.exports.filenameFormatField.components.submission.properties.id'
            )
          case 'CREATED_AT':
            return `${t(
              'settings.exports.filenameFormatField.components.submission.properties.createdAt'
            )} (${component.valueType.format})`
          case 'PUBLISHED_AT':
            return `${t(
              'settings.exports.filenameFormatField.components.submission.properties.publishedAt'
            )} (${component.valueType.format})`
          case 'LANGUAGE': {
            switch (component.valueType.format) {
              case 'LANGUAGE_CODE':
                return t(
                  'settings.exports.filenameFormatField.languageFormat.options.languageCode'
                )
              case 'LANGUAGE_NAME':
                return t(
                  'settings.exports.filenameFormatField.languageFormat.options.languageName'
                )
            }
          }
          case 'VERSION':
            return t(
              'settings.exports.filenameFormatField.components.submission.properties.version'
            )
        }
      }

      default:
        return absurd(component)
    }
  }

  const getComponentIcon = (component: SubmissionFilenameComponent) => {
    switch (component.type) {
      case 'formField': {
        const Icon = formElementIcon(component.valueType.name)

        return <Icon size='small' />
      }
      case 'submitter':
        return <UserIcon size='small' />
      case 'customer':
        return <BriefcaseIcon size='small' />
      case 'form':
        return <FormIcon size='small' />
      case 'submission':
        return <DocumentIcon size='small' />
    }
  }

  return (
    <Badge
      ref={ref}
      style={{ cursor: 'pointer', gap: 6 }}
      __css={{
        ':active': {
          marginTop: '0.1rem'
        },
        ':hover': {
          outlineColor: 'primary.100',
          outlineWidth: '0.1rem',
          outlineStyle: 'solid'
        }
      }}
      pl={3}
      alignItems='center'
      {...other}
    >
      {getComponentIcon(component)}
      <Text>{getComponentLabel(component)}</Text>
      <IconButton
        variant='filled'
        colorVariant='neutral'
        size='mini'
        ml={1}
        onClick={() => onRemove(index)}
      >
        <CloseIcon size='small' />
      </IconButton>
    </Badge>
  )
})

interface SubmissionFilenameBuilderProps {
  filenameComponents: SubmissionFilenameComponent[]
  formFields: Field[]
  setFilenameComponents: (components: SubmissionFilenameComponent[]) => void
}

export const SubmissionFilenameBuilder = ({
  filenameComponents,
  formFields,
  setFilenameComponents
}: SubmissionFilenameBuilderProps) => {
  const handleUpdateComponent = (
    index: number,
    component: SubmissionFilenameComponent
  ) => {
    setFilenameComponents(
      filenameComponents.map((c, i) => (i === index ? component : c))
    )
  }

  const handleRemoveComponent = (index: number) => {
    setFilenameComponents(filenameComponents.filter((_, i) => i !== index))
  }

  const renderComponent = (
    component: SubmissionFilenameComponent,
    index: number
  ) => {
    switch (component.type) {
      case 'formField':
        return (
          <FormFieldComponentMenu
            key={`${component.type}-${component.fieldKey}-${index}`}
            fields={formFields}
            formFieldComponent={component}
            onChange={(formFieldComponent) => {
              handleUpdateComponent(index, formFieldComponent)
            }}
          >
            <FilenameComponentBadge
              index={index}
              component={component}
              onRemove={handleRemoveComponent}
            />
          </FormFieldComponentMenu>
        )
      case 'submission':
        return (
          <SubmissionComponentMenu
            key={`${component.type}-${index}`}
            submissionComponent={component}
            onChange={(submissionComponent) => {
              handleUpdateComponent(index, submissionComponent)
            }}
          >
            <FilenameComponentBadge
              index={index}
              component={component}
              onRemove={handleRemoveComponent}
            />
          </SubmissionComponentMenu>
        )
      case 'customer':
        return (
          <CustomerComponentMenu
            key={`${component.type}-${index}`}
            customerComponent={component}
            onChange={(customerComponent) => {
              handleUpdateComponent(index, customerComponent)
            }}
          >
            <FilenameComponentBadge
              index={index}
              component={component}
              onRemove={handleRemoveComponent}
            />
          </CustomerComponentMenu>
        )
      case 'form':
        return (
          <FormComponentMenu
            key={`${component.type}-${index}`}
            formComponent={component}
            onChange={(formComponent) => {
              handleUpdateComponent(index, formComponent)
            }}
          >
            <FilenameComponentBadge
              index={index}
              component={component}
              onRemove={handleRemoveComponent}
            />
          </FormComponentMenu>
        )
      case 'submitter':
        return (
          <SubmitterComponentMenu
            key={`${component.type}-${index}`}
            submitterComponent={component}
            onChange={(submitterComponent) => {
              handleUpdateComponent(index, submitterComponent)
            }}
          >
            <FilenameComponentBadge
              index={index}
              component={component}
              onRemove={handleRemoveComponent}
            />
          </SubmitterComponentMenu>
        )
      default:
        return absurd(component)
    }
  }

  return (
    <Flex pt={4} gap={2} flexWrap='wrap' alignItems='center'>
      <SelectFilenameComponentMenu
        fields={formFields}
        onAdd={(component) =>
          setFilenameComponents([...filenameComponents, component])
        }
      />

      {filenameComponents.map((component, index) =>
        renderComponent(component, index)
      )}
    </Flex>
  )
}
