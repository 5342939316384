import React, {
  ChangeEvent,
  ComponentType,
  ReactElement,
  ReactNode,
  useCallback,
  useRef
} from 'react'
import {
  Alert,
  AlertIcon,
  Body,
  Box,
  Button,
  Card,
  Flex,
  FormField,
  FormFieldLabel,
  Input,
  system,
  Text,
  Textarea
} from '@woorcs/design-system'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Reader'
import { constant, constNull, pipe } from 'fp-ts/function'

import * as FormElement from '../../FormDocument/Element'
import {
  useDateRangeInputElement,
  useDateInputElement,
  useTextInputElement,
  useNumberInputElement,
  useEmailInputElement,
  useLocationInputElement,
  useTimeInputElement,
  useSignatureInputElement,
  useImageInputElement,
  useSelectInputElement,
  useGroupInputElement,
  useFormContext
  // useMultiSelectInputElement
} from '../../react'
import { FormEnvironment } from '../../Form'

import { ResponseSetInput } from './ResponseSetInput'

type ElementRendererProps<T> = {
  element: T
}

type ElementRenderer<T extends FormElement.FormElementType> = ComponentType<
  ElementRendererProps<T>
>

// -------------------------------------------------------------------------------------
// static
// -------------------------------------------------------------------------------------

const renderStaticElement: (
  element: FormElement.StaticElementType
) => R.Reader<FormEnvironment, ReactElement> =
  FormElement.StaticElementType.match({
    Alert: (element) =>
      pipe(
        element.children,
        R.traverseArray(renderStaticElement),
        R.map((body) => (
          // eslint-disable-next-line react/jsx-key
          <Alert variant={element.status}>
            <AlertIcon />
            {body}
          </Alert>
        ))
      ),
    Text: (element) =>
      pipe(
        R.ask<FormEnvironment>(),
        R.map(({ i18n }) => (
          // eslint-disable-next-line react/jsx-key
          <Body key={element.id}>{i18n.getText(element.text)}</Body>
        ))
      )
  })

interface StaticElementRendererProps {
  element: FormElement.StaticElementType
}

const StaticElementRenderer = ({ element }: StaticElementRendererProps) =>
  pipe(useFormContext(), renderStaticElement(element))

// -------------------------------------------------------------------------------------
// inputs
// -------------------------------------------------------------------------------------

export const TextInputElementRenderer: ElementRenderer<
  FormElement.TextInputElement
> = ({ element }) => {
  const { value, onChange, placeholder } = useTextInputElement(element)
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  return pipe(value, O.getOrElse(constant('')), (value) => {
    if (element.options.multiline) {
      return (
        <Textarea
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
        />
      )
    }

    return (
      <Input value={value} placeholder={placeholder} onChange={handleChange} />
    )
  })
}

export const NumberInputElementRenderer: ElementRenderer<
  FormElement.NumberInputElement
> = ({ element }) => {
  const { value, onChange, placeholder } = useNumberInputElement(element)
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange(Number(e.target.value))
    },
    [onChange]
  )

  return pipe(value, O.fold(constant(''), String), (value) => (
    <Input value={value} placeholder={placeholder} onChange={handleChange} />
  ))
}

export const EmailInputElementRenderer: ElementRenderer<
  FormElement.EmailInputElement
> = ({ element }) => {
  const { value, onChange, placeholder } = useEmailInputElement(element)
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  return pipe(value, O.getOrElse(constant('')), (value) => (
    <Input value={value} placeholder={placeholder} onChange={handleChange} />
  ))
}

export const LocationInputElemenRenderer: ElementRenderer<
  FormElement.LocationInputElement
> = ({ element }) => {
  const { value } = useLocationInputElement(element)

  return pipe(value as any, O.getOrElse(constant('')), (value) => (
    <Box>
      <Input value={value} placeholder='Enter location' mb={4} />

      <Box bg='primary.100' borderRadius='medium' height={320} />
    </Box>
  ))
}

export const DateRangeInputElementRenderer: ElementRenderer<
  FormElement.DateRangeInputElement
> = ({ element }) => {
  const { value } = useDateRangeInputElement(element)
  const handleChange = useCallback(() => {
    // onChange
  }, [])

  return pipe(value, O.getOrElse(constant({ from: '', to: '' })), (value) => {
    return (
      <Box>
        <FormField label='from' mb={4}>
          <Input
            type='datetime-local'
            value={value.from}
            onChange={handleChange}
          />
        </FormField>
        <FormField label='to'>
          <Input
            type='datetime-local'
            value={value.to}
            onChange={handleChange}
          />
        </FormField>
      </Box>
    )
  })
}

export const DateInputElementRenderer: ElementRenderer<
  FormElement.DateInputElement
> = ({ element }) => {
  const { value, onChange } = useDateInputElement(element)
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  return pipe(value, O.getOrElse(constant('')), (value) => {
    return <Input type='datetime-local' value={value} onChange={handleChange} />
  })
}

export const TimeInputElementRenderer: ElementRenderer<
  FormElement.TimeInputElement
> = ({ element }) => {
  const { value, onChange } = useTimeInputElement(element)
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  return pipe(value, O.getOrElse(constant('')), (value) => {
    return <Input type='time' value={value} onChange={handleChange} />
  })
}

export const SignatureInputElementRenderer: ElementRenderer<
  FormElement.SignatureInputElement
> = ({ element }) => {
  const { value } = useSignatureInputElement(element)

  return pipe(value, O.getOrElse(constant('')), () => (
    <Box>
      <Flex
        bg='grey.50'
        borderRadius='medium'
        height={180}
        justifyContent='center'
        alignItems='center'
      >
        <Text>Sign here</Text>
      </Flex>
    </Box>
  ))
}

export const ImageInputElementRenderer: ElementRenderer<
  FormElement.ImageInputElement
> = ({ element }) => {
  const { value, onChange } = useImageInputElement(element)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]

      if (file) {
        onChange([URL.createObjectURL(file)])
      }
    },
    [onChange]
  )

  return (
    <Box>
      {pipe(
        value,
        O.match(constNull, (value) => {
          return <system.img src={value[0]} maxWidth='100%' />
        })
      )}

      <Input
        type='file'
        css={{
          'input[type="file"]': {
            opacity: 0
          }
        }}
        accept='image/*'
        multiple={element.options.multiple}
        rightAddon={
          <Button
            colorVariant='neutral'
            size='small'
            onClick={(e) => {
              e.preventDefault()

              if (inputRef.current) {
                inputRef.current.focus()
              }
            }}
          >
            Browse
          </Button>
        }
        onChange={handleChange}
      />
    </Box>
  )
}

export const GroupInputElementRenderer: ElementRenderer<
  FormElement.GroupInputElement
> = ({ element }) => {
  const { value } = useGroupInputElement(element)

  return pipe(value, O.getOrElse(constant({})), () => {
    return <Button>Add</Button>
  })
}

export const SelectInputElementRenderer: ElementRenderer<
  FormElement.SelectInputElement
> = ({ element }) => {
  const { value, responseSet, onChange } = useSelectInputElement(element)

  return pipe(
    responseSet,
    O.fold(constNull, (responseSet) => (
      <ResponseSetInput
        value={value as any}
        responseSet={responseSet}
        onChange={onChange}
      />
    ))
  )
}

export const renderFormInputElement = FormElement.InputElementType.matchStrict({
  TextInput: (element) => <TextInputElementRenderer element={element} />,
  NumberInput: (element) => <NumberInputElementRenderer element={element} />,
  EmailInput: (element) => <EmailInputElementRenderer element={element} />,
  LocationInput: (element) => <LocationInputElemenRenderer element={element} />,
  DateInput: (element) => <DateInputElementRenderer element={element} />,
  DateRangeInput: (element) => (
    <DateRangeInputElementRenderer element={element} />
  ),
  TimeInput: (element) => <TimeInputElementRenderer element={element} />,
  SignatureInput: (element) => (
    <SignatureInputElementRenderer element={element} />
  ),
  ImageInput: (element) => <ImageInputElementRenderer element={element} />,
  SelectInput: (element) => <SelectInputElementRenderer element={element} />,
  MultiSelectInput: (element) => (
    <SelectInputElementRenderer element={element as any} />
  ),
  GroupInput: (element) => <GroupInputElementRenderer element={element} />
})

const InputElementFieldInner = system('div')({
  // pointerEvents: 'none'
})

interface InputElementFieldProps {
  label: string
  informativeText: string | null
  required: boolean
  children: ReactNode
}

const InputElementField = ({
  label,
  informativeText,
  required,
  children
}: InputElementFieldProps) => (
  <Card
    bg='white'
    elevation={1}
    border='base'
    borderRadius='medium'
    position='relative'
  >
    <InputElementFieldInner>
      <Box position='relative' p={5}>
        <Box mb={4}>
          <FormFieldLabel
            fontSize='base'
            informativeText={informativeText}
            required={required}
          >
            {label}
          </FormFieldLabel>
        </Box>
        {children}
      </Box>
    </InputElementFieldInner>
  </Card>
)

interface RenderFormElementProps {
  element: FormElement.FormElementType
}

export const RenderFormElement = ({ element }: RenderFormElementProps) => {
  if (FormElement.isInputElement(element)) {
    return (
      <InputElementField
        informativeText={element.informativeText.text}
        label={element.label.text}
        required={!element.optional}
      >
        {renderFormInputElement(element)}
      </InputElementField>
    )
  }

  if (FormElement.isStaticElement(element)) {
    return <StaticElementRenderer element={element} />
  }

  return null
}
