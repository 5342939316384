import { ChangeEvent, useCallback, useMemo } from 'react'
import * as O from 'fp-ts/Option'
import { Box, Flex, Input, SearchIcon } from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import { pipe, identity } from 'fp-ts/function'

import { FilterList } from '../filters'
import { ListPageFilters, ListPageFiltersProps } from '../ListPage'
import { PaginiationLimitSelect } from '../PaginiationLimitSelect'

import { SubmissionFilter, useSubmissionListFiltersContext } from './filters'

interface SubmissionListFiltersProps extends ListPageFiltersProps {
  hiddenFilters?: SubmissionFilter[]
}

export const SubmissionListFilters = ({
  hiddenFilters: hiddenFiltersProp = [],
  ...other
}: SubmissionListFiltersProps) => {
  const { t } = useTranslation('submissions')
  const { filters, limit, setLimit, setFilter } =
    useSubmissionListFiltersContext()
  const hiddenFilters = useMemo(
    () => ['query', ...hiddenFiltersProp],
    [hiddenFiltersProp]
  )

  const inputChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilter('query', O.some(event.target.value))
    },
    [setFilter]
  )

  return (
    <ListPageFilters
      flexWrap='wrap'
      flexDirection='column'
      alignItems='stretch'
      {...other}
    >
      <Box flex={1} mb={4}>
        <Input
          flexGrow={1}
          width='auto'
          placeholder={t('submissionsPage.searchInput.placeholder')}
          leftAddon={<SearchIcon size='small' />}
          variant='filled'
          value={pipe(
            filters.query,
            O.fold(() => '', identity)
          )}
          onChange={inputChanged}
        />
      </Box>
      <Flex justifyContent='space-between' alignItems='center' flex={1}>
        <FilterList hiddenFilters={hiddenFilters} />
        <PaginiationLimitSelect value={limit} onChange={setLimit} />
      </Flex>
    </ListPageFilters>
  )
}
