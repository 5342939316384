import React, { ReactNode, ReactElement, Children, forwardRef } from 'react'
import { whenTrue, isString } from '@woorcs/utils'
import { PropsOf } from '@emotion/react'
import { motion, Variants } from 'framer-motion'

import { focusStyle } from '../../../styles'
import { system } from '../../../system'
import { Spinner } from '../../feedback/Spinner'

import {
  colorVariants,
  sizeVariants,
  filled,
  outlined,
  plain,
  ButtonVariant,
  ButtonColorVariant,
  ButtonSizeVariant
} from './variants'
import { ButtonLabel } from './ButtonLabel'

const whenIsFullWidth = whenTrue(['fullWidth'])

export interface ButtonStyleProps extends PropsOf<typeof system.button> {
  size?: ButtonSizeVariant
  variant?: ButtonVariant
  colorVariant?: ButtonColorVariant
  fullWidth?: boolean
  children?: ReactNode
}

export const StyledButton = system('button')<ButtonStyleProps>(
  {
    position: 'relative',
    appearance: 'none',
    display: 'inline-flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    textAlign: 'center',
    lineHeight: 1,
    fontWeight: 'bold',
    borderRadius: '100%',
    background: 'none',
    border: 'none',
    outline: 'none',
    color: 'inherit',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    transition:
      'background 0.3s ease-out, border 0.3s ease-out, color 0.3s ease-out',
    ':focus': focusStyle,
    ':active': {
      paddingTop: '0.125rem'
    }
  },
  whenIsFullWidth({
    display: 'flex',
    width: '100%'
  }),
  sizeVariants,
  ...colorVariants,
  filled({
    ':disabled, &[disabled]': {
      backgroundColor: 'grey.100',
      color: 'grey.200'
    }
  }),
  outlined({
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'currentColor',
    backgroundColor: 'transparent',
    ':disabled, &[disabled]': {
      color: 'grey.light'
    }
  }),
  plain({
    ':disabled, &[disabled]': {
      color: 'grey.100'
    }
  })
)

const animationVariants: Record<string, Variants> = {
  inner: {
    initial: {
      opacity: 1,
      transition: {
        delay: 0.2
      }
    },
    loading: {
      opacity: 0
    }
  },
  spinner: {
    initial: {
      opacity: 0,
      scale: 0.55
    },
    loading: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.2
      }
    }
  }
}

const transition = {
  duration: 0.15,
  ease: [0.48, 0.15, 0.25, 0.96]
}

const ButtonIcon: React.FC<PropsOf<typeof system.span>> = (props) => {
  const { children, ...rest } = props

  const icon = React.isValidElement(children)
    ? React.cloneElement(children, {
        'aria-hidden': true,
        focusable: false
      } as any)
    : children

  return <system.span {...rest}>{icon}</system.span>
}

interface ButtonSpinnerProps {
  loading: boolean
  size: ButtonSizeVariant
}

const getSpinnerSize = (size: ButtonSizeVariant) => {
  if (size === 'mini') {
    return size
  }

  return 'small'
}

const ButtonSpinner = ({ loading, size }: ButtonSpinnerProps) => (
  <motion.div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }}
    animate={loading ? 'loading' : 'initial'}
    initial='initial'
    variants={animationVariants.spinner}
    transition={transition}
  >
    <Spinner size={getSpinnerSize(size)} color='currentColor' mr={2} />
  </motion.div>
)

export interface ButtonProps extends ButtonStyleProps {
  loading?: boolean
  leftIcon?: ReactElement
  rightIcon?: ReactElement
}

export const Button = forwardRef<'button', ButtonProps>(
  (
    {
      size = 'medium',
      variant = 'filled',
      colorVariant = 'primary',
      loading = false,
      leftIcon,
      rightIcon,
      children,
      ...other
    },
    ref
  ) => {
    const getLeftAddon = () => {
      if (leftIcon) {
        return <ButtonIcon>{leftIcon}</ButtonIcon>
      }

      return null
    }

    const leftAddon = getLeftAddon()

    const childs = Children.map(children, (child) => {
      if (isString(child)) {
        return <ButtonLabel size={size}>{child}</ButtonLabel>
      }

      return child
    })

    return (
      <StyledButton
        ref={ref}
        size={size}
        variant={variant}
        colorVariant={colorVariant}
        {...other}
      >
        <ButtonSpinner loading={loading} size={size} />
        <motion.div
          style={{
            display: 'inline-flex',
            alignItems: 'center'
          }}
          animate={loading ? 'loading' : 'initial'}
          initial='initial'
          variants={animationVariants.inner}
          transition={transition}
        >
          {leftAddon}
          {childs}
          {rightIcon && <ButtonIcon>{rightIcon}</ButtonIcon>}
        </motion.div>
      </StyledButton>
    )
  }
)

export const PrimaryButton = forwardRef<'button', ButtonProps>((props, ref) => (
  <Button ref={ref} {...props} colorVariant='primary' />
))

export const SecondaryButton = forwardRef<'button', ButtonProps>(
  (props, ref) => <Button ref={ref} {...props} colorVariant='secondary' />
)

export const NeutralButton = forwardRef<'button', ButtonProps>((props, ref) => (
  <Button ref={ref} {...props} colorVariant='neutral' />
))

export const SuccessButton = forwardRef<'button', ButtonProps>((props, ref) => (
  <Button ref={ref} {...props} colorVariant='success' />
))

export const WarningButton = forwardRef<'button', ButtonProps>((props, ref) => (
  <Button ref={ref} {...props} colorVariant='warning' />
))

export const DangerButton = forwardRef<'button', ButtonProps>((props, ref) => (
  <Button ref={ref} {...props} colorVariant='danger' />
))
