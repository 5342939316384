import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useMemo } from 'react'

import {
  ValueOfFiltersConfig,
  dateRangeFilter,
  filters,
  formFilter,
  tagFilter,
  userFilter,
  useFiltersContext,
  useFilters,
  customerFilter,
  queryFilter
} from '../filters'

const createSubmissionFilters = (t: TFunction) =>
  filters({
    query: queryFilter(t('submissionList.filters.query.label')),
    creatorId: userFilter(t('submissionList.filters.creatorId.label')),
    formId: formFilter(t('submissionList.filters.formId.label')),
    published: dateRangeFilter(t('submissionList.filters.published.label')),
    tags: tagFilter(t('submissionList.filters.tags.label')),
    customerId: customerFilter(t('submissionList.filters.customerId.label'))
  })

export const useSubmissionFilters = (
  initialValues: Partial<SubmissionListFilters> = {}
) => {
  const { t } = useTranslation('submissions')
  const filters = useMemo(() => createSubmissionFilters(t), [t])

  return useFilters(filters, initialValues)
}

export type SubmissionListFiltersConfig = ReturnType<
  typeof createSubmissionFilters
>

type SubmissionListFilters = ValueOfFiltersConfig<SubmissionListFiltersConfig>

export type SubmissionFilter = keyof SubmissionListFilters

export const useSubmissionListFiltersContext = () =>
  useFiltersContext<SubmissionListFiltersConfig>()
