import { Flex, FormFieldLabel, Box, useSnackbar } from '@woorcs/design-system'
import { useEffect, useMemo, useState } from 'react'
import { FormElement } from '@woorcs/form'
import * as ElementTree from '@woorcs/types/ElementTree'
import * as E from 'fp-ts/Either'
import { useMutation } from 'urql'
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'
import {
  decodeSubmissionFilenameFormat,
  SubmissionFilenameComponent,
  SubmissionFilenameFormat
} from '@woorcs/submission-filename-format'
import { pipe } from 'fp-ts/function'

import {
  SubmissionFilenameFormFragment,
  UpdateFormSubmissionFilenameDocument
} from './__generated__/SubmissionFilenameForm'
import { Field } from './SelectFieldModal'
import { SubmissionFilenamePreview } from './Preview'
import { SubmissionFilenameBuilder } from './FilenameBuilder'

const validElementTypes = [
  'TextInput',
  'DateInput',
  'TimeInput',
  'NumberInput',
  'EmailInput'
]

const getFormFields = (form: SubmissionFilenameFormFragment) => {
  return ElementTree.toArray(
    form.latestRevision.definition as unknown as ElementTree.ElementTree
  )
    .filter(FormElement.InputElementType.is)
    .filter((element) => validElementTypes.includes(element.type))
    .map(
      (element): Field => ({
        key: element.key.toString(),
        type: element.type as
          | 'TextInput'
          | 'DateInput'
          | 'TimeInput'
          | 'NumberInput'
          | 'EmailInput',
        label: element.label.text
      })
    )
}

interface SubmissionFilenameFormProps {
  form: SubmissionFilenameFormFragment
}

export const SubmissionFilenameForm = ({
  form
}: SubmissionFilenameFormProps) => {
  const { t } = useTranslation('form-details')
  const [initialFilenameFormat] = useState<SubmissionFilenameFormat>(
    pipe(
      decodeSubmissionFilenameFormat(form.filenameFormat),
      E.getOrElseW(() => ({
        delimiter: null,
        components: []
      }))
    )
  )
  const [delimiter] = useState(initialFilenameFormat.delimiter)
  const [filenameComponents, setFilenameComponents] = useState<
    SubmissionFilenameComponent[]
  >(initialFilenameFormat.components)
  const formFields = useMemo(() => getFormFields(form), [form])
  const formDefinition = form.latestRevision.definition
  const filenameFormat = useMemo(
    (): SubmissionFilenameFormat => ({
      delimiter,
      components: filenameComponents
    }),
    [delimiter, filenameComponents]
  )
  const [, updateFormSubmissionFilename] = useMutation(
    UpdateFormSubmissionFilenameDocument
  )
  const { showSnackbar } = useSnackbar()
  const debouncedSubmit = useDebouncedCallback(
    (filenameComponents: SubmissionFilenameComponent[]) => {
      updateFormSubmissionFilename({
        input: {
          formId: form.id,
          format: {
            delimiter,
            components: filenameComponents
          }
        }
      })
        // .then(() => {
        //   showSnackbar({
        //     variant: 'success',
        //     title: t('settings.submissions.filename.updateSuccess')
        //   })
        // })
        .catch(() => {
          showSnackbar({
            title: t('settings.exports.filenameFormatField.updateError'),
            variant: 'danger'
          })
        })
    },
    300
  )

  useEffect(() => {
    debouncedSubmit.callback(filenameComponents)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filenameComponents])

  return (
    <Box>
      <FormFieldLabel
        description={t('settings.exports.filenameFormatField.description')}
      >
        {t('settings.exports.filenameFormatField.label')}
      </FormFieldLabel>
      <Box mb={4}>
        <SubmissionFilenameBuilder
          filenameComponents={filenameComponents}
          formFields={formFields}
          setFilenameComponents={setFilenameComponents}
        />
      </Box>

      {/* <Input
        value={delimiter ?? ''}
        onChange={(e) => setDelimiter(e.target.value)}
      /> */}

      <Flex flexDirection='column' alignItems='flex-start' gap={4}>
        <SubmissionFilenamePreview
          filenameFormat={filenameFormat}
          formDefinition={formDefinition}
        />
      </Flex>
    </Box>
  )
}
