/* eslint-disable complexity */
import { pipe } from 'fp-ts/function'
import * as D from 'io-ts/Decoder'

const BaseSubmissionFilenameComponentDecoder = D.partial({
  prefix: D.nullable(D.string),
  suffix: D.nullable(D.string)
})

export type BaseSubmissionFilenameComponent = D.TypeOf<
  typeof BaseSubmissionFilenameComponentDecoder
>

const createSubmissionFilenameComponentDecoder = <T extends string, P>(
  type: T,
  properties: D.Decoder<unknown, P>
) => {
  return pipe(
    D.struct({
      type: D.literal(type)
    }),
    D.intersect(BaseSubmissionFilenameComponentDecoder),
    D.intersect(properties)
  )
}

export type StringWhitespaceFormat =
  | 'NONE'
  | 'SNAKE_CASE'
  | 'KEBAB_CASE'
  | 'CAMEL_CASE'
  | 'PASCAL_CASE'

export type StringCapitalizationFormat =
  | 'NONE'
  | 'UPPER_CASE'
  | 'LOWER_CASE'
  | 'CAPITALIZE'

export type SubmissionFilenameStringValue = {
  type: 'string'
  formatWhitespace: StringWhitespaceFormat
  formatCapitalization: StringCapitalizationFormat
  convertSpecialCharacters: boolean
}

const createSubmissionFilenameStringValueDecoder = <T extends string>(
  name: T
) => {
  return D.struct({
    name: D.literal(name),
    type: D.literal('string'),
    formatWhitespace: D.literal(
      'NONE',
      'SNAKE_CASE',
      'KEBAB_CASE',
      'CAMEL_CASE',
      'PASCAL_CASE'
    ),
    formatCapitalization: D.literal(
      'NONE',
      'UPPER_CASE',
      'LOWER_CASE',
      'CAPITALIZE'
    ),
    convertSpecialCharacters: D.boolean
  })
}

export type DateFormat =
  | 'YYYYMMDD'
  | 'MMDDYYYY'
  | 'DDMMYYYY'
  | 'YYYYMMDDHHMM'
  | 'DDMMYYYYHHMM'

export type SubmissionFilenameDateValue = {
  type: 'date'
  format: DateFormat
}

const createSubmissionFilenameDateValueDecoder = <T extends string>(
  name: T
) => {
  return D.struct({
    name: D.literal(name),
    type: D.literal('date'),
    format: D.literal(
      'YYYYMMDD',
      'MMDDYYYY',
      'DDMMYYYY',
      'YYYYMMDDHHMM',
      'DDMMYYYYHHMM'
    )
  })
}

export type TimeFormat = 'HHMM' | 'HHMMSS'

export type SubmissionFilenameTimeValue = {
  type: 'time'
  format: TimeFormat
}

const createSubmissionFilenameTimeValueDecoder = <T extends string>(
  name: T
) => {
  return D.struct({
    name: D.literal(name),
    type: D.literal('time'),
    format: D.literal('HHMM', 'HHMMSS')
  })
}

type SubmissionFilenameNumberValue = {
  type: 'number'
}

const createSubmissionFilenameNumberValueDecoder = <T extends string>(
  name: T
) => {
  return D.struct({
    name: D.literal(name),
    type: D.literal('number')
  })
}

type SubmissionFilenameEmailValue = {
  type: 'email'
}

const createSubmissionFilenameEmailValueDecoder = <T extends string>(
  name: T
) => {
  return D.struct({
    name: D.literal(name),
    type: D.literal('email')
  })
}

export type LanguageFormat = 'LANGUAGE_CODE' | 'LANGUAGE_NAME'

export type SubmissionFilenameLanguageValue = {
  type: 'language'
  format: LanguageFormat
}

const createSubmissionFilenameLanguageValueDecoder = <T extends string>(
  name: T
) => {
  return D.struct({
    name: D.literal(name),
    type: D.literal('language'),
    format: D.literal('LANGUAGE_CODE', 'LANGUAGE_NAME')
  })
}

type SubmissionFilenameIdValue = {
  type: 'id'
}

const createSubmissionFilenameIdValueDecoder = <T extends string>(name: T) => {
  return D.struct({
    name: D.literal(name),
    type: D.literal('id')
  })
}

export type SubmissionFilenameValue =
  | SubmissionFilenameStringValue
  | SubmissionFilenameDateValue
  | SubmissionFilenameTimeValue
  | SubmissionFilenameNumberValue
  | SubmissionFilenameEmailValue
  | SubmissionFilenameLanguageValue
  | SubmissionFilenameIdValue

export type SubmitterValueName =
  | 'NAME'
  | 'FIRST_NAME'
  | 'LAST_NAME'
  | 'EMAIL'
  | 'ID'

const SubmitterValueTypeDecoder = D.union(
  createSubmissionFilenameStringValueDecoder('NAME'),
  createSubmissionFilenameStringValueDecoder('FIRST_NAME'),
  createSubmissionFilenameStringValueDecoder('LAST_NAME'),
  createSubmissionFilenameEmailValueDecoder('EMAIL'),
  createSubmissionFilenameIdValueDecoder('ID')
)

export type SubmitterValueType = D.TypeOf<typeof SubmitterValueTypeDecoder>

const SubmissionFilenameSubmitterComponentDecoder =
  createSubmissionFilenameComponentDecoder(
    'submitter',
    D.struct({
      valueType: SubmitterValueTypeDecoder
    })
  )

export type SubmissionFilenameSubmitterComponent = D.TypeOf<
  typeof SubmissionFilenameSubmitterComponentDecoder
>

export type SubmissionFilenameCustomerProperty = 'NAME' | 'ID'

const CustomerValueTypeDecoder = D.union(
  createSubmissionFilenameStringValueDecoder('NAME'),
  createSubmissionFilenameIdValueDecoder('ID')
)

export type CustomerValueType = D.TypeOf<typeof CustomerValueTypeDecoder>

const SubmissionFilenameCustomerComponentDecoder =
  createSubmissionFilenameComponentDecoder(
    'customer',
    D.struct({
      valueType: CustomerValueTypeDecoder
    })
  )

export type SubmissionFilenameCustomerComponent = D.TypeOf<
  typeof SubmissionFilenameCustomerComponentDecoder
>

const SubmissionValueTypeDecoder = D.union(
  createSubmissionFilenameIdValueDecoder('ID'),
  createSubmissionFilenameDateValueDecoder('PUBLISHED_AT'),
  createSubmissionFilenameDateValueDecoder('CREATED_AT'),
  createSubmissionFilenameNumberValueDecoder('VERSION'),
  createSubmissionFilenameLanguageValueDecoder('LANGUAGE')
)

export type SubmissionValueType = D.TypeOf<typeof SubmissionValueTypeDecoder>

const SubmissionFilenameSubmissionComponentDecoder =
  createSubmissionFilenameComponentDecoder(
    'submission',
    D.struct({
      valueType: SubmissionValueTypeDecoder
    })
  )

export type SubmissionFilenameSubmissionComponent = D.TypeOf<
  typeof SubmissionFilenameSubmissionComponentDecoder
>

export type SubmissionFilenameFormProperty = 'TITLE' | 'ID'

const FormValueTypeDecoder = D.union(
  createSubmissionFilenameStringValueDecoder('TITLE'),
  createSubmissionFilenameIdValueDecoder('ID')
)

export type FormValueType = D.TypeOf<typeof FormValueTypeDecoder>

const SubmissionFilenameFormComponentDecoder =
  createSubmissionFilenameComponentDecoder(
    'form',
    D.struct({
      valueType: FormValueTypeDecoder
    })
  )

const FormFieldKeyDecoder = D.string

const FormFieldValueTypeDecoder = D.union(
  createSubmissionFilenameStringValueDecoder('TextInput'),
  createSubmissionFilenameNumberValueDecoder('NumberInput'),
  createSubmissionFilenameDateValueDecoder('DateInput'),
  createSubmissionFilenameTimeValueDecoder('TimeInput'),
  createSubmissionFilenameEmailValueDecoder('EmailInput')
)

export type FormFieldValueType = D.TypeOf<typeof FormFieldValueTypeDecoder>

const SubmissionFilenameFormFieldComponentDecoder =
  createSubmissionFilenameComponentDecoder(
    'formField',
    D.struct({
      fieldKey: FormFieldKeyDecoder,
      valueType: FormFieldValueTypeDecoder
    })
  )

export type SubmissionFilenameFormFieldComponent = D.TypeOf<
  typeof SubmissionFilenameFormFieldComponentDecoder
>

export type SubmissionFilenameFormComponent = D.TypeOf<
  typeof SubmissionFilenameFormComponentDecoder
>

const SubmissionFilenameComponentDecoder = D.sum('type')({
  submitter: SubmissionFilenameSubmitterComponentDecoder,
  customer: SubmissionFilenameCustomerComponentDecoder,
  formField: SubmissionFilenameFormFieldComponentDecoder,
  form: SubmissionFilenameFormComponentDecoder,
  submission: SubmissionFilenameSubmissionComponentDecoder
})

export type SubmissionFilenameComponent = D.TypeOf<
  typeof SubmissionFilenameComponentDecoder
>

const SubmissionFilenameFormatDecoder = pipe(
  D.struct({
    components: D.array(SubmissionFilenameComponentDecoder)
  }),
  D.intersect(
    D.partial({
      delimiter: D.nullable(D.string)
    })
  )
)

export type SubmissionFilenameFormat = D.TypeOf<
  typeof SubmissionFilenameFormatDecoder
>

export const decodeSubmissionFilenameFormat = (format: unknown) => {
  return SubmissionFilenameFormatDecoder.decode(format)
}
