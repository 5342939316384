import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import * as Optional from 'monocle-ts/Optional'
import * as DE from '@nll/datum/DatumEither'
import {
  List,
  Spinner,
  Box,
  Text,
  Flex,
  useSnackbar
} from '@woorcs/design-system'
import { constNull, pipe } from 'fp-ts/function'
import { useMutation, useQuery } from 'urql'
import { queryToDatumEither } from '@woorcs/graphql'
import { getNonEmptyArrayOptional } from '@woorcs/utils'

import {
  TagListQueryDocument,
  TagListQuery,
  DeleteTagDocument
} from './__generated__/TagsList'
import { TagListItem } from './Item'

export const tagsResponseOptional = pipe(
  Optional.id<TagListQuery>(),
  Optional.prop('organization'),
  Optional.prop('tags'),
  Optional.compose(getNonEmptyArrayOptional())
)

export const TagList = () => {
  const { t } = useTranslation('tags')
  const { showSnackbar } = useSnackbar()
  const [, removeTag] = useMutation(DeleteTagDocument)

  const handleRemoveTag = useCallback(
    (tagId: string) => {
      removeTag({
        id: tagId
      }).then(() => {
        showSnackbar({
          title: t('tagsPage.removeTagSuccessSnackbar.title'),
          variant: 'danger'
        })
      })
    },
    [removeTag, showSnackbar, t]
  )

  return pipe(
    useQuery({ query: TagListQueryDocument }),
    queryToDatumEither(tagsResponseOptional),
    DE.squash(
      (loading) => {
        if (loading) {
          return (
            <Box display='flex' justifyContent='center' alignItems='center'>
              <Spinner size='small' />
            </Box>
          )
        }

        return (
          <Flex py={8} justifyContent='center'>
            <Text>{t('tagsPage.noTagsYet')}</Text>
          </Flex>
        )
      },
      constNull,
      (tags) => (
        <List mb={5}>
          {tags.map((tag, index) => (
            <TagListItem key={index} tag={tag} onRemove={handleRemoveTag} />
          ))}
        </List>
      )
    )
  )
}
