/* eslint-disable @typescript-eslint/no-use-before-define */
import { identity, pipe } from 'fp-ts/function'
import * as RA from 'fp-ts/ReadonlyArray'
import * as RR from 'fp-ts/ReadonlyRecord'
import * as R from 'fp-ts/Reader'
import * as O from 'fp-ts/Option'
import {
  FormSubmissionStore,
  SumbissionRenderer,
  RuleEngine,
  Translator,
  FormSubmissionData,
  Locale,
  ResponseSetRegistry,
  DocumentsRegistry
} from '@woorcs/form'

import { InspectionFormDefinition } from './InspectionFormDefinition'
import * as InspectionFormDocument from './InspectionFormDocument'

interface InspectionFormSubmissionElementRenderer<A>
  extends SumbissionRenderer.FormSubmissionElementRenderer<A> {
  renderRoot: (children: A[]) => A
  renderPage: (
    page: InspectionFormDocument.InspectionFormPageElement,
    title: string,
    children: A[]
  ) => A
}

export const elementRenderer = <A>(
  r: InspectionFormSubmissionElementRenderer<A>
) => r

interface Environment<A>
  extends SumbissionRenderer.Environment<A>,
    InspectionFormSubmissionElementRenderer<A> {
  document: InspectionFormDocument.InspectionFormDocument
  // renderer: InspectionFormSubmissionElementRenderer<A>
  renderPage: (
    page: InspectionFormDocument.InspectionFormPageElement,
    title: string,
    children: A[]
  ) => A
}

type SubmissionRenderer<R, A> = R.Reader<Environment<R>, A>

// -------------------------------------------------------------------------------------
// render
// -------------------------------------------------------------------------------------

const renderPage = <A>(
  page: InspectionFormDocument.InspectionFormPageElement
): SubmissionRenderer<A, O.Option<A>> =>
  pipe(
    InspectionFormDocument.isPageVisible(page),
    R.chainW((isVisible) => {
      if (!isVisible) {
        return R.of(O.none)
      }

      return pipe(
        R.ask<Environment<A>>(),
        R.chainW(({ renderPage, i18n, locale }) =>
          pipe(
            SumbissionRenderer.renderChildren<A>(page),
            R.map((children) =>
              renderPage(
                page,
                i18n.getText(page.title, locale),
                RA.toArray(children)
              )
            )
          )
        ),
        R.map(O.some)
      )
    })
  )

const renderPages = <A>(
  pages: InspectionFormDocument.InspectionFormPageElement[]
): SubmissionRenderer<A, ReadonlyArray<A>> =>
  pipe(
    pages,
    R.traverseArray((page) => renderPage<A>(page)),
    R.map((children) => pipe(children, RA.filterMap(identity)))
  )

const render = <A>(): SubmissionRenderer<A, A> =>
  pipe(
    R.ask<Environment<A>>(),
    R.chain(({ document, renderRoot }) =>
      pipe(
        renderPages<A>(document.children),
        R.map((pages) => pipe(RA.toArray(pages), renderRoot))
      )
    )
  )

// -------------------------------------------------------------------------------------
// main
// -------------------------------------------------------------------------------------

export const main = <A>() => render<A>()

interface Options<A> {
  definition: InspectionFormDefinition
  data: FormSubmissionData
  locale?: Locale.Locale
  renderer: InspectionFormSubmissionElementRenderer<A>
}

export const getInspectionFormSubmissionRenderer = <A>({
  definition,
  data,
  locale = definition.i18n.defaultLanguage,
  renderer
}: Options<A>): A => {
  const program = main<A>()
  const submission = FormSubmissionStore.getFormSubmissionStore(data)
  const responseSets = ResponseSetRegistry.getResponseSetRegistry(
    definition.responseSets
  )
  const documents = DocumentsRegistry.getDocumentsRegistry(definition.documents)
  const ruleEngine = RuleEngine.getRuleEngine((key) =>
    pipe(data, RR.lookup(key))
  )
  const i18n = Translator.getTranslator(definition.i18n)

  return program({
    locale,
    i18n,
    submission,
    document: definition,
    ruleEngine,
    responseSets,
    documents,
    isSubmission: true,
    ...renderer
  })
}
