import React, { ChangeEvent, useCallback } from 'react'
import {
  Box,
  Flex,
  NativeSelect,
  NativeSelectOption
} from '@woorcs/design-system'
import { ElementRule, Condition } from '@woorcs/form'

import {
  ConditionGroupForm,
  ConditionGroupConditionLabel
} from '../ConditionGroupForm'

type ElementRuleEffectSelectProps = {
  value: ElementRule.ElementRuleEffect
  onChange(value: ElementRule.ElementRuleEffect): void
}

const ElementRuleEffectSelect = ({
  value,
  onChange
}: ElementRuleEffectSelectProps) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value

      if (!ElementRule.ElementRuleEffect.is(value)) {
        return
      }

      onChange(value)
    },
    [onChange]
  )

  return (
    <NativeSelect
      value={value}
      placeholder='Select action'
      onChange={handleChange}
    >
      <NativeSelectOption value='hide'>Hide question</NativeSelectOption>
      <NativeSelectOption value='show'>Show question</NativeSelectOption>
      <NativeSelectOption value='exclude-from-report'>
        Exclude from report
      </NativeSelectOption>
    </NativeSelect>
  )
}

type ElementRuleFormProps = {
  rule: ElementRule.ElementRule
  onChange(rule: ElementRule.ElementRule): void
}

export const ElementRuleForm = ({ rule, onChange }: ElementRuleFormProps) => {
  const handleEffectChange = useCallback(
    (effect: ElementRule.ElementRuleEffect) => {
      onChange({
        ...rule,
        effect
      })
    },
    [onChange, rule]
  )

  const handleConditionChange = useCallback(
    (condition: Condition.Condition) => {
      onChange({
        ...rule,
        condition
      })
    },
    [onChange, rule]
  )

  return (
    <Box>
      <Box
        borderBottomColor='grey.100'
        borderBottomWidth={1}
        borderBottomStyle='solid'
        mb={4}
        pb={4}
      >
        <ConditionGroupForm
          group={rule.condition as Condition.ConditionGroup}
          onChange={handleConditionChange}
        />
      </Box>
      <Flex>
        <ConditionGroupConditionLabel>then</ConditionGroupConditionLabel>

        <ElementRuleEffectSelect
          value={rule.effect}
          onChange={handleEffectChange}
        />
      </Flex>
    </Box>
  )
}
