import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { ListItemTitle, SelectableListItem } from '@woorcs/design-system'
import * as O from 'fp-ts/Option'
import * as string from 'fp-ts/string'
import { useDebouncedCallback } from 'use-debounce'
import { formElementIcon } from '@woorcs/inspection-form-editor/src/element'
import { useTranslation } from 'react-i18next'
import { FormFieldValueType } from '@woorcs/submission-filename-format'

import { SearchModal } from '@app/components/SearchModal'

export interface Field {
  key: string
  label: string
  type: FormFieldValueType['name']
}

interface SelectFieldModalItemProps {
  isSelected: boolean
  field: Field
  onSelect(field: Field): void
}

const SelectFieldModalItem = ({
  isSelected,
  field,
  onSelect
}: SelectFieldModalItemProps) => {
  const handleClick = useCallback(() => {
    onSelect(field)
  }, [onSelect, field])
  const Icon = formElementIcon(field.type)

  return (
    <SelectableListItem
      alignItems='center'
      isSelected={isSelected}
      dense
      onClick={handleClick}
    >
      <Icon mr={3} size='small' />
      <ListItemTitle>{field.label}</ListItemTitle>
    </SelectableListItem>
  )
}

interface SelectFieldModalProps {
  fields: Field[]
  searchPlaceholder?: string
  selectedKey: O.Option<string>
  children: ReactElement
  onSelect(field: Field): void
}

export const SelectFieldModal = ({
  fields,
  onSelect,
  selectedKey,
  children
}: SelectFieldModalProps) => {
  const { t } = useTranslation('form-details')
  const isSelected = (field: Field) =>
    O.getEq(string.Eq).equals(O.some(field.key), selectedKey)

  const [query, setQuery] = useState<string>('')
  const [debounced, setDebounced] = useState(query)
  const debounce = useDebouncedCallback((query: string) => {
    setDebounced(query)
  }, 200)
  const filteredFields = useMemo(() => {
    return fields.filter((field) =>
      field.label.toLowerCase().includes(debounced.toLowerCase())
    )
  }, [debounced, fields])

  useEffect(() => {
    debounce.callback(query)
  }, [debounce, query])

  return (
    <SearchModal
      items={filteredFields}
      query={query}
      searchPlaceholder={t(
        'settings.exports.filenameFormatField.selectFieldModal.searchPlaceholder'
      )}
      renderItem={(field) => (
        <SelectFieldModalItem
          key={field.key}
          field={field}
          isSelected={isSelected(field)}
          onSelect={onSelect}
        />
      )}
      onQueryChange={setQuery}
    >
      {children}
    </SearchModal>
  )
}
