/* eslint-disable max-lines */
import {
  FilterButton,
  PlusIcon,
  FilterButtonLabel,
  MenuButton,
  Menu,
  Flex,
  Spinner,
  MenuContent,
  MenuItem,
  MenuOptionGroup,
  MenuOption,
  Switch,
  Text,
  Input,
  MenuSeparator
} from '@woorcs/design-system'
import { absurd, pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import { ReactElement, ReactNode, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CustomerValueType,
  DateFormat,
  FormFieldValueType,
  FormValueType,
  LanguageFormat,
  StringCapitalizationFormat,
  StringWhitespaceFormat,
  SubmissionFilenameComponent,
  SubmissionFilenameCustomerComponent,
  SubmissionFilenameDateValue,
  SubmissionFilenameFormComponent,
  SubmissionFilenameFormFieldComponent,
  SubmissionFilenameSubmissionComponent,
  SubmissionFilenameSubmitterComponent,
  SubmissionValueType,
  SubmitterValueType
} from '@woorcs/submission-filename-format'

import { Field, SelectFieldModal } from './SelectFieldModal'

const componentTypes: SubmissionFilenameComponent['type'][] = [
  'submission',
  'submitter',
  'customer',
  'form',
  'formField'
]

interface DateFormatMenuProps {
  children: ReactElement
  value: DateFormat | null
  hideOnClick?: boolean
  onSelect: (dateFormat: DateFormat) => void
}

export const DateFormatMenu = ({
  onSelect,
  children,
  hideOnClick = true
}: DateFormatMenuProps) => {
  return (
    <Menu>
      <MenuButton>{children}</MenuButton>
      <MenuContent gutter={4} aria-label='date format'>
        <MenuItem
          hideOnClick={hideOnClick}
          onClick={() => onSelect('YYYYMMDD')}
        >
          YYYY-MM-DD
        </MenuItem>
        <MenuItem
          hideOnClick={hideOnClick}
          onClick={() => onSelect('YYYYMMDDHHMM')}
        >
          YYYY-MM-DD HH:mm
        </MenuItem>
        <MenuItem
          hideOnClick={hideOnClick}
          onClick={() => onSelect('MMDDYYYY')}
        >
          MM-DD-YYYY
        </MenuItem>
        <MenuItem
          hideOnClick={hideOnClick}
          onClick={() => onSelect('DDMMYYYY')}
        >
          DD-MM-YYYY
        </MenuItem>
        <MenuItem onClick={() => onSelect('DDMMYYYYHHMM')}>
          DD-MM-YYYY HH:mm
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}

interface LanguageFormatMenuProps {
  children: ReactElement
  value: LanguageFormat | null
  hideOnClick?: boolean
  onSelect: (languageFormat: LanguageFormat) => void
}

export const LanguageFormatMenu = ({
  onSelect,
  children,
  hideOnClick = true
}: LanguageFormatMenuProps) => {
  const { t } = useTranslation('form-details')

  return (
    <Menu>
      <MenuButton>{children}</MenuButton>
      <MenuContent gutter={4} aria-label='date format'>
        <MenuItem
          hideOnClick={hideOnClick}
          onClick={() => onSelect('LANGUAGE_CODE')}
        >
          {t(
            'settings.exports.filenameFormatField.languageFormat.options.languageCode'
          )}
        </MenuItem>
        <MenuItem
          hideOnClick={hideOnClick}
          onClick={() => onSelect('LANGUAGE_NAME')}
        >
          {t(
            'settings.exports.filenameFormatField.languageFormat.options.languageName'
          )}
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}

interface WhitespaceMenuProps {
  children: ReactElement
  value: StringWhitespaceFormat | null
  onSelect: (transform: StringWhitespaceFormat) => void
}

const WhitespaceMenu = ({ children, value, onSelect }: WhitespaceMenuProps) => {
  const { t } = useTranslation('form-details')

  return (
    <Menu>
      <MenuButton>{children}</MenuButton>
      <MenuContent gutter={4} aria-label='string transform'>
        <MenuOptionGroup
          value={value}
          type='radio'
          onChange={(value) => onSelect(value as StringWhitespaceFormat)}
        >
          <MenuOption value='NONE' hideOnClick={false}>
            {t('settings.exports.filenameFormatField.whitespace.options.none')}
          </MenuOption>
          <MenuOption value='SNAKE_CASE' hideOnClick={false}>
            {t(
              'settings.exports.filenameFormatField.whitespace.options.snakeCase'
            )}
          </MenuOption>
          <MenuOption value='KEBAB_CASE' hideOnClick={false}>
            {t(
              'settings.exports.filenameFormatField.whitespace.options.kebabCase'
            )}
          </MenuOption>
          <MenuOption value='CAMEL_CASE' hideOnClick={false}>
            {t(
              'settings.exports.filenameFormatField.whitespace.options.camelCase'
            )}
          </MenuOption>
          <MenuOption value='PASCAL_CASE' hideOnClick={false}>
            {t(
              'settings.exports.filenameFormatField.whitespace.options.pascalCase'
            )}
          </MenuOption>
        </MenuOptionGroup>
      </MenuContent>
    </Menu>
  )
}

interface CapitalizationMenuProps {
  children: ReactElement
  value: StringCapitalizationFormat | null
  onSelect: (transform: StringCapitalizationFormat) => void
}

const CapitalizationMenu = ({
  children,
  value,
  onSelect
}: CapitalizationMenuProps) => {
  const { t } = useTranslation('form-details')

  return (
    <Menu>
      <MenuButton>{children}</MenuButton>
      <MenuContent gutter={4} aria-label='string transform'>
        <MenuOptionGroup
          value={value}
          type='radio'
          onChange={(value) => onSelect(value as StringCapitalizationFormat)}
        >
          <MenuOption value='NONE'>
            {t(
              'settings.exports.filenameFormatField.capitalization.options.none'
            )}
          </MenuOption>
          <MenuOption value='UPPER_CASE'>
            {t(
              'settings.exports.filenameFormatField.capitalization.options.upperCase'
            )}
          </MenuOption>
          <MenuOption value='LOWER_CASE'>
            {t(
              'settings.exports.filenameFormatField.capitalization.options.lowerCase'
            )}
          </MenuOption>
          <MenuOption value='CAPITALIZE'>
            {t(
              'settings.exports.filenameFormatField.capitalization.options.capitalize'
            )}
          </MenuOption>
        </MenuOptionGroup>
      </MenuContent>
    </Menu>
  )
}

interface ComponentMenuProps<T extends SubmissionFilenameComponent> {
  children: ReactElement
  component: T
  menuButton: ReactElement
  onChange: (component: T) => void
}

const ComponentMenu = <T extends SubmissionFilenameComponent>({
  children,
  menuButton,
  component,
  onChange
}: ComponentMenuProps<T>) => {
  const { t } = useTranslation('form-details')

  const getWhitespaceLabel = (whitespace: StringWhitespaceFormat) => {
    switch (whitespace) {
      case 'NONE':
        return t('settings.exports.filenameFormatField.whitespace.options.none')
      case 'SNAKE_CASE':
        return t(
          'settings.exports.filenameFormatField.whitespace.options.snakeCase'
        )
      case 'KEBAB_CASE':
        return t(
          'settings.exports.filenameFormatField.whitespace.options.kebabCase'
        )
      case 'CAMEL_CASE':
        return t(
          'settings.exports.filenameFormatField.whitespace.options.camelCase'
        )
      case 'PASCAL_CASE':
        return t(
          'settings.exports.filenameFormatField.whitespace.options.pascalCase'
        )
    }
  }

  const getCapitalizationLabel = (
    capitalization: StringCapitalizationFormat
  ) => {
    switch (capitalization) {
      case 'NONE':
        return t(
          'settings.exports.filenameFormatField.capitalization.options.none'
        )
      case 'UPPER_CASE':
        return t(
          'settings.exports.filenameFormatField.capitalization.options.upperCase'
        )
      case 'CAPITALIZE':
        return t(
          'settings.exports.filenameFormatField.capitalization.options.capitalize'
        )
      case 'LOWER_CASE':
        return t(
          'settings.exports.filenameFormatField.capitalization.options.lowerCase'
        )
    }
  }

  const renderValueTypeMenuItems = () => {
    const valueType = component.valueType

    switch (valueType.type) {
      case 'string': {
        const showCapitalizationMenu =
          valueType.formatWhitespace !== 'PASCAL_CASE' &&
          valueType.formatWhitespace !== 'CAMEL_CASE'

        return (
          <>
            <WhitespaceMenu
              value={valueType.formatWhitespace ?? null}
              onSelect={(transform) =>
                onChange({
                  ...component,
                  valueType: {
                    ...valueType,
                    formatWhitespace: transform
                  }
                })
              }
            >
              <MenuItem justifyContent='space-between' gridColumnGap={4}>
                <Text fontWeight='semi-bold'>
                  {t('settings.exports.filenameFormatField.whitespace.label')}
                </Text>
                <Text>{getWhitespaceLabel(valueType.formatWhitespace)}</Text>
              </MenuItem>
            </WhitespaceMenu>
            {showCapitalizationMenu && (
              <CapitalizationMenu
                value={valueType.formatCapitalization ?? null}
                onSelect={(transform) =>
                  onChange({
                    ...component,
                    valueType: {
                      ...valueType,
                      formatCapitalization: transform
                    }
                  })
                }
              >
                <MenuItem justifyContent='space-between' gridColumnGap={4}>
                  <Text fontWeight='semi-bold'>
                    {t(
                      'settings.exports.filenameFormatField.capitalization.label'
                    )}
                  </Text>
                  <Text>
                    {getCapitalizationLabel(valueType.formatCapitalization)}
                  </Text>
                </MenuItem>
              </CapitalizationMenu>
            )}
            <MenuItem
              justifyContent='space-between'
              gridColumnGap={4}
              hideOnClick={false}
            >
              <Text fontWeight='semi-bold'>
                {t(
                  'settings.exports.filenameFormatField.convertSpecialCharacters'
                )}
              </Text>
              <Switch
                checked={valueType.convertSpecialCharacters}
                onChange={(e) =>
                  onChange({
                    ...component,
                    valueType: {
                      ...valueType,
                      convertSpecialCharacters: e.target.checked
                    }
                  })
                }
              />
            </MenuItem>
          </>
        )
      }
      case 'date':
        return (
          <DateFormatMenu
            hideOnClick={false}
            value={valueType.format ?? null}
            onSelect={(format) =>
              onChange({
                ...component,
                valueType: {
                  ...valueType,
                  format
                } as SubmissionFilenameDateValue
              })
            }
          >
            <MenuItem
              justifyContent='space-between'
              gridColumnGap={4}
              hideOnClick={false}
            >
              <Text fontWeight='semi-bold'>
                {t('settings.exports.filenameFormatField.dateFormat.label')}
              </Text>
              <Text>{valueType.format}</Text>
            </MenuItem>
          </DateFormatMenu>
        )
    }
  }

  return (
    <Menu>
      {menuButton}

      <MenuContent gutter={4} aria-label='filters'>
        {children}

        {renderValueTypeMenuItems()}

        <MenuSeparator />

        <MenuItem hideOnClick={false}>
          <Flex
            flex={1}
            alignItems='center'
            justifyContent='space-between'
            my={4}
            gridColumnGap={4}
          >
            <Text fontWeight='semi-bold'>
              {t('settings.exports.filenameFormatField.prefix.label')}
            </Text>
            <Input
              size='small'
              variant='inline'
              placeholder={t(
                'settings.exports.filenameFormatField.prefix.placeholder'
              )}
              value={component.prefix ?? ''}
              onChange={(e) =>
                onChange({
                  ...component,
                  prefix: e.target.value
                })
              }
            />
          </Flex>
        </MenuItem>
        <MenuItem hideOnClick={false}>
          <Flex
            flex={1}
            alignItems='center'
            justifyContent='space-between'
            my={4}
            gridColumnGap={4}
          >
            <Text fontWeight='semi-bold'>
              {t('settings.exports.filenameFormatField.suffix.label')}
            </Text>
            <Input
              size='small'
              variant='inline'
              placeholder={t(
                'settings.exports.filenameFormatField.suffix.placeholder'
              )}
              value={component.suffix ?? ''}
              onChange={(e) =>
                onChange({
                  ...component,
                  suffix: e.target.value
                })
              }
            />
          </Flex>
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}

interface CustomerPropertyMenuProps {
  children: ReactElement
  onSelect: (customerProperty: CustomerValueType['name']) => void
}

const CustomerPropertyMenu = ({
  children,
  onSelect
}: CustomerPropertyMenuProps) => {
  const { t } = useTranslation('form-details')

  return (
    <Menu>
      <MenuButton>{children}</MenuButton>

      <MenuContent gutter={4} aria-label='filters'>
        <MenuItem onClick={() => onSelect('NAME')}>
          {t(
            'settings.exports.filenameFormatField.components.customer.properties.name'
          )}
        </MenuItem>
        <MenuItem onClick={() => onSelect('ID')}>
          {t(
            'settings.exports.filenameFormatField.components.customer.properties.id'
          )}
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}

const createCustomerValueType = (
  property: CustomerValueType['name']
): CustomerValueType => {
  switch (property) {
    case 'NAME': {
      return {
        name: 'NAME',
        type: 'string',
        formatWhitespace: 'NONE',
        formatCapitalization: 'NONE',
        convertSpecialCharacters: false
      }
    }
    case 'ID': {
      return {
        name: 'ID',
        type: 'id'
      }
    }
  }
}

interface CustomerComponentMenuProps {
  children: ReactElement
  customerComponent: SubmissionFilenameCustomerComponent
  onChange: (customerComponent: SubmissionFilenameCustomerComponent) => void
}

export const CustomerComponentMenu = ({
  customerComponent,
  children,
  onChange
}: CustomerComponentMenuProps) => {
  const { t } = useTranslation('form-details')

  const getValueTypeLabel = (valueType: CustomerValueType) => {
    switch (valueType.name) {
      case 'NAME':
        return t(
          'settings.exports.filenameFormatField.components.customer.properties.name'
        )
      case 'ID':
        return t(
          'settings.exports.filenameFormatField.components.customer.properties.id'
        )
    }
  }
  return (
    <ComponentMenu
      menuButton={<MenuButton>{children}</MenuButton>}
      component={customerComponent}
      onChange={(component) =>
        onChange(component as SubmissionFilenameCustomerComponent)
      }
    >
      <CustomerPropertyMenu
        onSelect={(property) =>
          onChange({
            ...customerComponent,
            valueType: createCustomerValueType(property)
          })
        }
      >
        <MenuItem justifyContent='space-between'>
          <Text fontWeight='semi-bold'>
            {t(
              'settings.exports.filenameFormatField.components.customer.propertiesLabel'
            )}
          </Text>
          <Text>{getValueTypeLabel(customerComponent.valueType)}</Text>
        </MenuItem>
      </CustomerPropertyMenu>
    </ComponentMenu>
  )
}

interface SubmissionPropertyMenuProps {
  children: ReactElement
  onSelect: (submissionProperty: SubmissionValueType['name']) => void
}

const SubmissionPropertyMenu = ({
  children,
  onSelect
}: SubmissionPropertyMenuProps) => {
  const { t } = useTranslation('form-details')

  return (
    <Menu>
      <MenuButton>{children}</MenuButton>

      <MenuContent gutter={4} aria-label='filters'>
        <MenuItem onClick={() => onSelect('ID')}>
          {t(
            'settings.exports.filenameFormatField.components.submission.properties.id'
          )}
        </MenuItem>
        <MenuItem onClick={() => onSelect('PUBLISHED_AT')}>
          {t(
            'settings.exports.filenameFormatField.components.submission.properties.publishedAt'
          )}
        </MenuItem>
        <MenuItem onClick={() => onSelect('CREATED_AT')}>
          {t(
            'settings.exports.filenameFormatField.components.submission.properties.createdAt'
          )}
        </MenuItem>
        <MenuItem onClick={() => onSelect('VERSION')}>
          {t(
            'settings.exports.filenameFormatField.components.submission.properties.version'
          )}
        </MenuItem>
        <MenuItem onClick={() => onSelect('LANGUAGE')}>
          {t(
            'settings.exports.filenameFormatField.components.submission.properties.language'
          )}
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}

export const createSubmissionValueType = (
  property: SubmissionValueType['name']
): SubmissionValueType => {
  switch (property) {
    case 'ID': {
      return {
        name: 'ID',
        type: 'id'
      }
    }
    case 'PUBLISHED_AT': {
      return {
        name: 'PUBLISHED_AT',
        type: 'date',
        format: 'YYYYMMDD'
      }
    }

    case 'CREATED_AT': {
      return {
        name: 'CREATED_AT',
        type: 'date',
        format: 'YYYYMMDD'
      }
    }

    case 'VERSION': {
      return {
        name: 'VERSION',
        type: 'number'
      }
    }

    case 'LANGUAGE': {
      return {
        name: 'LANGUAGE',
        type: 'language',
        format: 'LANGUAGE_CODE'
      }
    }
  }
}

interface SubmissionComponentMenuProps {
  children: ReactElement
  submissionComponent: SubmissionFilenameSubmissionComponent
  onChange: (submissionComponent: SubmissionFilenameSubmissionComponent) => void
}

export const SubmissionComponentMenu = ({
  submissionComponent,
  children,
  onChange
}: SubmissionComponentMenuProps) => {
  const { t } = useTranslation('form-details')

  const getValueTypeLabel = (valueType: SubmissionValueType) => {
    switch (valueType.name) {
      case 'ID':
        return t(
          'settings.exports.filenameFormatField.components.submission.properties.id'
        )
      case 'PUBLISHED_AT':
        return t(
          'settings.exports.filenameFormatField.components.submission.properties.publishedAt'
        )
      case 'CREATED_AT':
        return t(
          'settings.exports.filenameFormatField.components.submission.properties.createdAt'
        )
      case 'VERSION':
        return t(
          'settings.exports.filenameFormatField.components.submission.properties.version'
        )
      case 'LANGUAGE':
        return t(
          'settings.exports.filenameFormatField.components.submission.properties.language'
        )
    }
  }
  return (
    <ComponentMenu
      menuButton={<MenuButton>{children}</MenuButton>}
      component={submissionComponent}
      onChange={(component) =>
        onChange(component as SubmissionFilenameSubmissionComponent)
      }
    >
      <SubmissionPropertyMenu
        onSelect={(property) =>
          onChange({
            ...submissionComponent,
            valueType: createSubmissionValueType(property)
          })
        }
      >
        <MenuItem justifyContent='space-between'>
          <Text fontWeight='semi-bold'>
            {t(
              'settings.exports.filenameFormatField.components.submission.propertiesLabel'
            )}
          </Text>
          <Text>{getValueTypeLabel(submissionComponent.valueType)}</Text>
        </MenuItem>
      </SubmissionPropertyMenu>
    </ComponentMenu>
  )
}

interface FormPropertyMenuProps {
  children: ReactElement
  onSelect: (formProperty: FormValueType['name']) => void
}

const FormPropertyMenu = ({ children, onSelect }: FormPropertyMenuProps) => {
  const { t } = useTranslation('form-details')

  return (
    <Menu>
      <MenuButton>{children}</MenuButton>

      <MenuContent gutter={4} aria-label='filters'>
        <MenuItem onClick={() => onSelect('ID')}>
          {t(
            'settings.exports.filenameFormatField.components.form.properties.id'
          )}
        </MenuItem>
        <MenuItem onClick={() => onSelect('TITLE')}>
          {t(
            'settings.exports.filenameFormatField.components.form.properties.title'
          )}
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}

export const createFormValueType = (
  property: FormValueType['name']
): FormValueType => {
  switch (property) {
    case 'ID': {
      return {
        name: 'ID',
        type: 'id'
      }
    }
    case 'TITLE': {
      return {
        name: 'TITLE',
        type: 'string',
        formatWhitespace: 'NONE',
        formatCapitalization: 'NONE',
        convertSpecialCharacters: false
      }
    }
  }
}

interface FormComponentMenuProps {
  children: ReactElement
  formComponent: SubmissionFilenameFormComponent
  onChange: (formComponent: SubmissionFilenameFormComponent) => void
}

export const FormComponentMenu = ({
  formComponent,
  children,
  onChange
}: FormComponentMenuProps) => {
  const { t } = useTranslation('form-details')

  const getValueTypeLabel = (valueType: FormValueType) => {
    switch (valueType.name) {
      case 'ID':
        return t(
          'settings.exports.filenameFormatField.components.form.properties.id'
        )
      case 'TITLE':
        return t(
          'settings.exports.filenameFormatField.components.form.properties.title'
        )
    }
  }

  return (
    <ComponentMenu
      menuButton={<MenuButton>{children}</MenuButton>}
      component={formComponent}
      onChange={(component) =>
        onChange(component as SubmissionFilenameFormComponent)
      }
    >
      <FormPropertyMenu
        onSelect={(property) =>
          onChange({
            ...formComponent,
            valueType: createFormValueType(property)
          })
        }
      >
        <MenuItem justifyContent='space-between'>
          <Text fontWeight='semi-bold'>
            {t(
              'settings.exports.filenameFormatField.components.form.propertiesLabel'
            )}
          </Text>
          <Text>{getValueTypeLabel(formComponent.valueType)}</Text>
        </MenuItem>
      </FormPropertyMenu>
    </ComponentMenu>
  )
}

interface SubmitterPropertyMenuProps {
  children: ReactElement
  hideOnClick?: boolean
  onSelect: (submitterProperty: SubmitterValueType['name']) => void
}

const SubmitterPropertyMenu = ({
  children,
  hideOnClick = true,
  onSelect
}: SubmitterPropertyMenuProps) => {
  const { t } = useTranslation('form-details')

  return (
    <Menu>
      <MenuButton>{children}</MenuButton>

      <MenuContent gutter={4} aria-label='filters'>
        <MenuItem hideOnClick={hideOnClick} onClick={() => onSelect('NAME')}>
          {t(
            'settings.exports.filenameFormatField.components.submitter.properties.name'
          )}
        </MenuItem>
        <MenuItem
          hideOnClick={hideOnClick}
          onClick={() => onSelect('FIRST_NAME')}
        >
          {t(
            'settings.exports.filenameFormatField.components.submitter.properties.firstName'
          )}
        </MenuItem>
        <MenuItem
          hideOnClick={hideOnClick}
          onClick={() => onSelect('LAST_NAME')}
        >
          {t(
            'settings.exports.filenameFormatField.components.submitter.properties.lastName'
          )}
        </MenuItem>
        <MenuItem hideOnClick={hideOnClick} onClick={() => onSelect('ID')}>
          {t(
            'settings.exports.filenameFormatField.components.submitter.properties.id'
          )}
        </MenuItem>
        <MenuItem hideOnClick={hideOnClick} onClick={() => onSelect('EMAIL')}>
          {t(
            'settings.exports.filenameFormatField.components.submitter.properties.email'
          )}
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}

export const createSubmitterValueType = (
  property: SubmitterValueType['name']
): SubmitterValueType => {
  switch (property) {
    case 'ID': {
      return {
        name: 'ID',
        type: 'id'
      }
    }
    case 'NAME': {
      return {
        name: 'NAME',
        type: 'string',
        formatWhitespace: 'NONE',
        formatCapitalization: 'NONE',
        convertSpecialCharacters: false
      }
    }
    case 'FIRST_NAME': {
      return {
        name: 'FIRST_NAME',
        type: 'string',
        formatWhitespace: 'NONE',
        formatCapitalization: 'NONE',
        convertSpecialCharacters: false
      }
    }
    case 'LAST_NAME': {
      return {
        name: 'LAST_NAME',
        type: 'string',
        formatWhitespace: 'NONE',
        formatCapitalization: 'NONE',
        convertSpecialCharacters: false
      }
    }
    case 'EMAIL': {
      return {
        name: 'EMAIL',
        type: 'email'
      }
    }
  }
}

interface SubmitterComponentMenuProps {
  children: ReactElement
  submitterComponent: SubmissionFilenameSubmitterComponent
  onChange: (submitterComponent: SubmissionFilenameSubmitterComponent) => void
}

export const SubmitterComponentMenu = ({
  submitterComponent,
  children,
  onChange
}: SubmitterComponentMenuProps) => {
  const { t } = useTranslation('form-details')

  const getValueTypeLabel = (valueType: SubmitterValueType) => {
    switch (valueType.name) {
      case 'ID':
        return t(
          'settings.exports.filenameFormatField.components.submitter.properties.id'
        )
      case 'NAME':
        return t(
          'settings.exports.filenameFormatField.components.submitter.properties.name'
        )
      case 'FIRST_NAME':
        return t(
          'settings.exports.filenameFormatField.components.submitter.properties.firstName'
        )
      case 'LAST_NAME':
        return t(
          'settings.exports.filenameFormatField.components.submitter.properties.lastName'
        )
      case 'EMAIL':
        return t(
          'settings.exports.filenameFormatField.components.submitter.properties.email'
        )
    }
  }
  return (
    <ComponentMenu
      menuButton={<MenuButton>{children}</MenuButton>}
      component={submitterComponent}
      onChange={(component) =>
        onChange(component as SubmissionFilenameSubmitterComponent)
      }
    >
      <SubmitterPropertyMenu
        hideOnClick={false}
        onSelect={(property) =>
          onChange({
            ...submitterComponent,
            valueType: createSubmitterValueType(property)
          })
        }
      >
        <MenuItem justifyContent='space-between'>
          <Text fontWeight='semi-bold'>
            {t(
              'settings.exports.filenameFormatField.components.submitter.propertiesLabel'
            )}
          </Text>
          <Text>{getValueTypeLabel(submitterComponent.valueType)}</Text>
        </MenuItem>
      </SubmitterPropertyMenu>
    </ComponentMenu>
  )
}

export const createFormFieldValueType = (
  property: FormFieldValueType['name']
): FormFieldValueType => {
  switch (property) {
    case 'TextInput': {
      return {
        name: 'TextInput',
        type: 'string',
        formatWhitespace: 'NONE',
        formatCapitalization: 'NONE',
        convertSpecialCharacters: false
      }
    }
    case 'NumberInput': {
      return {
        name: 'NumberInput',
        type: 'number'
      }
    }
    case 'DateInput': {
      return {
        name: 'DateInput',
        type: 'date',
        format: 'YYYYMMDD'
      }
    }
    case 'TimeInput': {
      return {
        name: 'TimeInput',
        type: 'time',
        format: 'HHMM'
      }
    }
    case 'EmailInput': {
      return {
        name: 'EmailInput',
        type: 'email'
      }
    }
  }
}

interface FormFieldComponentMenuProps {
  children: ReactElement
  fields: Field[]
  formFieldComponent: SubmissionFilenameFormFieldComponent
  onChange: (formFieldComponent: SubmissionFilenameFormFieldComponent) => void
}

export const FormFieldComponentMenu = ({
  formFieldComponent,
  fields,
  children,
  onChange
}: FormFieldComponentMenuProps) => {
  const { t } = useTranslation('form-details')

  const getValueTypeLabel = (valueType: FormFieldValueType) => {
    switch (valueType.name) {
      case 'TextInput':
        return t(
          'settings.exports.filenameFormatField.components.formField.properties.textInput'
        )
      case 'NumberInput':
        return t(
          'settings.exports.filenameFormatField.components.formField.properties.numberInput'
        )
      case 'DateInput':
        return t(
          'settings.exports.filenameFormatField.components.formField.properties.dateInput'
        )
      case 'TimeInput':
        return t(
          'settings.exports.filenameFormatField.components.formField.properties.timeInput'
        )
    }
  }

  return (
    <ComponentMenu
      menuButton={<MenuButton>{children}</MenuButton>}
      component={formFieldComponent}
      onChange={(component) =>
        onChange(component as SubmissionFilenameFormFieldComponent)
      }
    >
      <SelectFieldModal
        fields={fields}
        selectedKey={O.none}
        onSelect={(field) => {
          onChange({
            ...formFieldComponent,
            fieldKey: field.key,
            valueType: createFormFieldValueType(field.type)
          })
        }}
      >
        <MenuItem justifyContent='space-between' hideOnClick={false}>
          <Text fontWeight='semi-bold'>
            {t(
              'settings.exports.filenameFormatField.components.formField.label'
            )}
          </Text>
          <Text>{getValueTypeLabel(formFieldComponent.valueType)}</Text>
        </MenuItem>
      </SelectFieldModal>
    </ComponentMenu>
  )
}

interface SelectFilenameComponentMenuProps {
  fields: Field[]
  onAdd: (component: SubmissionFilenameComponent) => void
}

export const SelectFilenameComponentMenu = ({
  fields,
  onAdd
}: SelectFilenameComponentMenuProps) => {
  const { t } = useTranslation('form-details')

  const renderMenuItem = (
    componentType: SubmissionFilenameComponent['type']
  ): ReactNode => {
    switch (componentType) {
      case 'submission': {
        return (
          <SubmissionPropertyMenu
            key={componentType}
            onSelect={(property) =>
              onAdd({
                type: 'submission',
                valueType: createSubmissionValueType(property)
              })
            }
          >
            <MenuItem hideOnClick={false}>
              {t(
                'settings.exports.filenameFormatField.components.submission.label'
              )}
            </MenuItem>
          </SubmissionPropertyMenu>
        )
      }

      case 'customer': {
        return (
          <CustomerPropertyMenu
            key={componentType}
            onSelect={(property) =>
              onAdd({
                type: 'customer',
                valueType: createCustomerValueType(property)
              })
            }
          >
            <MenuItem hideOnClick={false}>
              {t(
                'settings.exports.filenameFormatField.components.customer.label'
              )}
            </MenuItem>
          </CustomerPropertyMenu>
        )
      }

      case 'form': {
        return (
          <FormPropertyMenu
            key={componentType}
            onSelect={(property) =>
              onAdd({
                type: 'form',
                valueType: createFormValueType(property)
              })
            }
          >
            <MenuItem hideOnClick={false}>
              {t('settings.exports.filenameFormatField.components.form.label')}
            </MenuItem>
          </FormPropertyMenu>
        )
      }

      case 'submitter': {
        return (
          <SubmitterPropertyMenu
            key={componentType}
            onSelect={(property) =>
              onAdd({
                type: 'submitter',
                valueType: createSubmitterValueType(property)
              })
            }
          >
            <MenuItem hideOnClick={false}>
              {t(
                'settings.exports.filenameFormatField.components.submitter.label'
              )}
            </MenuItem>
          </SubmitterPropertyMenu>
        )
      }

      case 'formField': {
        return (
          <SelectFieldModal
            key={componentType}
            fields={fields}
            selectedKey={O.none}
            onSelect={(field) => {
              onAdd({
                type: 'formField',
                fieldKey: field.key,
                valueType: createFormFieldValueType(field.type)
              })
            }}
          >
            <MenuItem hideOnClick={false}>
              {t(
                'settings.exports.filenameFormatField.components.formField.label'
              )}
            </MenuItem>
          </SelectFieldModal>
        )
      }

      default:
        return absurd(componentType)
    }
  }

  return (
    <Menu>
      <MenuButton>
        <FilterButton>
          <FilterButtonLabel>
            <PlusIcon size='small' mr={2} />
            {t('settings.exports.filenameFormatField.addComponent')}
          </FilterButtonLabel>
        </FilterButton>
      </MenuButton>
      <MenuContent gutter={4} aria-label='filters'>
        <Suspense
          fallback={
            <Flex py={4} alignItems='center' justifyContent='center'>
              <Spinner size='small' />
            </Flex>
          }
        >
          {pipe(componentTypes, A.map(renderMenuItem))}
        </Suspense>
      </MenuContent>
    </Menu>
  )
}
