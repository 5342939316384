/* eslint-disable complexity */
import { format } from 'date-fns'
import { pipe } from 'fp-ts/function'
import * as A from 'fp-ts/Array'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as ElementTree from '@woorcs/types/ElementTree'
import * as O from 'fp-ts/Option'
import * as S from 'fp-ts/string'
import { FormDocument } from '@woorcs/form'
import { InputElementType } from '@woorcs/form/src/FormDocument/Element'

import {
  snakeCase,
  camelCase,
  pascalCase,
  capitalCase,
  kebabCase
} from './changeCase'
import {
  SubmissionFilenameComponent,
  SubmissionFilenameDateValue,
  SubmissionFilenameFormat,
  SubmissionFilenameFormFieldComponent,
  SubmissionFilenameLanguageValue,
  SubmissionFilenameStringValue,
  SubmissionFilenameValue
} from './decoders'

const DEFAULT_DELIMITER = '_'

const formatStringCapitalization =
  (
    capitalization: SubmissionFilenameStringValue['formatCapitalization'],
    whitespace: SubmissionFilenameStringValue['formatWhitespace']
  ) =>
  (value: string) => {
    if (whitespace === 'CAMEL_CASE' || whitespace === 'PASCAL_CASE') {
      return value
    }

    const getCapitalizeDelimiter = () => {
      switch (whitespace) {
        case 'SNAKE_CASE':
          return '_'
        case 'KEBAB_CASE':
          return '-'
        case 'NONE':
          return ' '
        default:
          return ' '
      }
    }
    switch (capitalization) {
      case 'UPPER_CASE':
        return value.toUpperCase()
      case 'LOWER_CASE':
        return value.toLowerCase()
      case 'CAPITALIZE': {
        return capitalCase(value, {
          delimiter: getCapitalizeDelimiter()
        })
      }
      default:
        return value
    }
  }

const formatStringWhitespace =
  (whitespace: SubmissionFilenameStringValue['formatWhitespace']) =>
  (value: string) => {
    switch (whitespace) {
      case 'SNAKE_CASE':
        return snakeCase(value)
      case 'KEBAB_CASE':
        return kebabCase(value)
      case 'CAMEL_CASE':
        return camelCase(value)
      case 'PASCAL_CASE':
        return pascalCase(value)
      default:
        return value
    }
  }

const transformSpecialCharacters =
  (
    convertSpecialCharacters: SubmissionFilenameStringValue['convertSpecialCharacters']
  ) =>
  (value: string) => {
    if (!convertSpecialCharacters) {
      return value
    }

    const normalized = value
      .normalize('NFKD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^\w\s-]/g, '')

    return normalized
  }

const formatStringComponentValue = (
  value: unknown,
  valueType: SubmissionFilenameStringValue
) => {
  if (!S.isString(value)) {
    return O.none
  }

  return pipe(
    value,
    formatStringWhitespace(valueType.formatWhitespace),
    formatStringCapitalization(
      valueType.formatCapitalization,
      valueType.formatWhitespace
    ),
    transformSpecialCharacters(valueType.convertSpecialCharacters),
    O.some
  )
}

const getFormFieldElement =
  (formDefinition: FormDocument.FormDocument) => (fieldKey: string) => {
    return pipe(
      formDefinition,
      ElementTree.find((element) => {
        if (!InputElementType.is(element)) {
          return false
        }

        return (element as InputElementType).key === fieldKey
      })
    ) as O.Option<InputElementType>
  }

const withPrefix =
  (component: SubmissionFilenameComponent) => (string: string) => {
    if (component.prefix) {
      return `${component.prefix}${string}`
    }

    return string
  }

const withSuffix =
  (component: SubmissionFilenameComponent) => (string: string) => {
    if (component.suffix) {
      return `${string}${component.suffix}`
    }

    return string
  }

type GetComponentValue = (
  component: SubmissionFilenameComponent
) => O.Option<unknown>

const formatDateComponent = (
  value: unknown,
  valueType: SubmissionFilenameDateValue
) => {
  if (typeof value !== 'number' && !(value instanceof Date)) {
    return O.none
  }

  try {
    switch (valueType.format) {
      case 'YYYYMMDD':
        return O.some(format(value, 'yyyy-MM-dd'))
      case 'MMDDYYYY':
        return O.some(format(value, 'MM-dd-yyyy'))
      case 'DDMMYYYY':
        return O.some(format(value, 'dd-MM-yyyy'))
      case 'YYYYMMDDHHMM':
        return O.some(format(value, 'yyyy-MM-dd_kk:mm'))
      case 'DDMMYYYYHHMM':
        return O.some(format(value, 'dd-MM-yyyy_kk:mm'))
      default:
        return O.none
    }
  } catch (error) {
    return O.none
  }
}

const formatLanguageValue = (
  value: unknown,
  valueType: SubmissionFilenameLanguageValue
) => {
  if (!S.isString(value)) {
    return O.none
  }

  switch (valueType.format) {
    case 'LANGUAGE_CODE':
      return O.some(value)
    case 'LANGUAGE_NAME':
      return O.some(value)
    default:
      return O.none
  }
}

const formatValue = (
  value: unknown,
  valueType: SubmissionFilenameValue
): O.Option<string> => {
  switch (valueType.type) {
    case 'string':
      return formatStringComponentValue(value, valueType)
    case 'date':
      return formatDateComponent(value, valueType)
    case 'time': {
      if (!S.isString(value)) {
        return O.none
      }

      return O.some(value)
    }
    case 'number':
      return O.some(String(value))
    case 'email':
      return O.some(String(value))
    case 'language':
      return formatLanguageValue(value, valueType)
    case 'id':
      return O.some(String(value))
  }
}

const formatFormFieldComponent = (
  component: SubmissionFilenameFormFieldComponent,
  formDefinition: FormDocument.FormDocument,
  value: unknown
) => {
  const fieldKey = component.fieldKey

  return pipe(
    fieldKey,
    getFormFieldElement(formDefinition),
    // If the element type does not match the component value type, return none
    O.filter((element) => {
      return element.type === component.valueType.name
    }),
    O.chain(() => formatValue(value, component.valueType))
  )
}

const formatComponent = (
  component: SubmissionFilenameComponent,
  formDefinition: FormDocument.FormDocument,
  getComponentValue: GetComponentValue
): O.Option<string> => {
  const value = getComponentValue(component)

  return pipe(
    value,
    O.chain((value) => {
      switch (component.type) {
        case 'formField':
          return formatFormFieldComponent(component, formDefinition, value)
        default:
          return formatValue(value, component.valueType)
      }
    })
  )
}

interface FormatSubmissionFilename {
  filenameFormat: SubmissionFilenameFormat
  formDefinition: FormDocument.FormDocument
  getComponentValue: GetComponentValue
}

export const formatSubmissionFilename = ({
  filenameFormat,
  formDefinition,
  getComponentValue
}: FormatSubmissionFilename): O.Option<string> => {
  const delimiter = filenameFormat.delimiter ?? DEFAULT_DELIMITER
  const componentLabels = pipe(
    filenameFormat.components,
    A.filterMap((component) => {
      return pipe(
        formatComponent(component, formDefinition, getComponentValue),
        O.map(withPrefix(component)),
        O.map(withSuffix(component))
      )
    }),
    NEA.fromArray
  )

  return pipe(
    componentLabels,
    O.map((componentLabels) => componentLabels.join(delimiter))
  )
}
