import * as Ariakit from '@ariakit/react'
import { AnimatePresence } from 'framer-motion'

import { PopoverContainer, PopoverContainerProps } from './PopoverContainer'

export type PopoverContentProps = Pick<
  Ariakit.PopoverOptions,
  'gutter' | 'shift' | 'flip' | 'slide'
> &
  PopoverContainerProps & {
    hideOnInteractOutside?: boolean
    portal?: boolean
  }

export const PopoverContent = ({
  gutter,
  children,
  hideOnInteractOutside = true,
  portal = true,
  ...other
}: PopoverContentProps) => {
  const store = Ariakit.usePopoverContext()

  if (!store) {
    throw new Error('PopoverContainer must be used within a Popover component')
  }

  const mounted = store.useState('mounted')

  return (
    <AnimatePresence>
      {mounted && (
        <Ariakit.Popover
          gutter={gutter}
          render={(props) => <PopoverContainer {...other} {...props} />}
          hideOnInteractOutside={hideOnInteractOutside}
          focusable={false}
          portal={portal}
          alwaysVisible
        >
          {children}
        </Ariakit.Popover>
      )}
    </AnimatePresence>
  )
}
